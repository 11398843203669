import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import {BrowserTracing} from '@sentry/tracing';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  try {
    Sentry.init({
      dsn: 'https://edfbb5a162bd4a8ea511a6b3269323dd@o1146321.ingest.sentry.io/6504703',
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new BrowserTracing({
          tracingOrigins: ['localhost', 'https://staging-hub.sunstone.in', 'https://hub.sunstone.in'],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  } catch (e) {
    console.log('Could not init sentry');
  }
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

/**
 * Cashfree returns to this url when payment is complete
 */
@Component({
  selector: 'app-webview-payment-helper',
  templateUrl: './webview-payment-helper.component.html',
  styleUrls: ['./webview-payment-helper.component.scss'],
})
export class WebviewPaymentHelperComponent implements OnInit {
  constructor(private router: Router) {}
  paymentStatus: 'success' | 'failure' = undefined;
  response: undefined;
  ngOnInit(): void {
    this.router.navigate(['/admission']);
  }
}

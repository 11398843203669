import {CookieService} from 'ngx-cookie';
import {WEBVIEW_USER_AGENT_NAME, WEBVIEW_USER_AGENT_NAME_IOS} from '../shared/shared.constants';
import {environment} from 'src/environments/environment';

export function getWebviewContext() {
  return window?.[WEBVIEW_USER_AGENT_NAME] || window?.[WEBVIEW_USER_AGENT_NAME_IOS];
}

export function getUserDetailsFromWebviewContext(contextObject: any):
  | {
      id: string;
      name: string;
      session_key: string;
      email: string;
      role: string;
      admission_year: string;
      utm_source?: string;
      utm_medium?: string;
      copy_link_enabled?: boolean
    }
  | false {
  const parsedContextObject = typeof contextObject === 'object' ? contextObject : JSON.parse(contextObject || '{}');
  if (parsedContextObject && parsedContextObject?.session_key) {
    return {
      id: parsedContextObject.id,
      name: parsedContextObject.name,
      session_key: parsedContextObject.session_key,
      email: parsedContextObject.email,
      role: parsedContextObject.role,
      admission_year: parsedContextObject.admission_year,
      utm_source: parsedContextObject.utm_source,
      utm_medium: parsedContextObject.utm_medium,
      copy_link_enabled: parsedContextObject.copy_link_enabled
    };
  }
  return false;
}
export function getUserDetailsFromWebviewContextOrCookie(cookie: CookieService) {
  const contextObject = getWebviewContext()?.getUser?.()
  if (contextObject) {
    return getUserDetailsFromWebviewContext(contextObject);
  }
  if (cookie.get('alu')) {
    return JSON.parse(cookie.get('alu') || '{}') as any;
  }
  if (cookie.get('psu')) {
    return JSON.parse(cookie.get('psu') || '{}') as any;
  }
  const newCookieName = environment.envName === 'development' ? "su_dev" : environment.envName === 'staging' ? "su_staging" : "su"
  if (cookie.get(newCookieName)) {
    return JSON.parse(cookie.get(newCookieName) || '{}') as any;
  }
  
  return false;
}

export function getUserDetailsFromAppWebviewContext(cookie: CookieService) {
  const contextObject = getWebviewContext()?.getUser?.()
  if (contextObject) {
    return getUserDetailsFromWebviewContext(contextObject);
  }
  
  return false;
}

export function getUserDetailsFromWebviewCookie(cookie: CookieService) {
  if (cookie.get('alu')) {
    return JSON.parse(cookie.get('alu') || '{}') as any;
  }
  if (cookie.get('psu')) {
    return JSON.parse(cookie.get('psu') || '{}') as any;
  }
  const newCookieName = environment.envName === 'development' ? "su_dev" : environment.envName === 'staging' ? "su_staging" : "su"
  if (cookie.get(newCookieName)) {
    return JSON.parse(cookie.get(newCookieName) || '{}') as any;
  }
  
  return false;
}

export const updateUserDetailsIntoCookie = (userData: any, cookie: CookieService) => {
  cookie.put('alu', JSON.stringify(userData), {
    expires: new Date(new Date().setDate(new Date().getDate() + 90)),
    domain: '.sunstone.in',
  })
  cookie.put('alu', JSON.stringify(userData), {
    expires: new Date(new Date().setDate(new Date().getDate() + 90))
  })
  cookie.put('psu', JSON.stringify(userData), {
    expires: new Date(new Date().setDate(new Date().getDate() + 90)),
    domain: '.sunstone.in',
  })
  cookie.put('psu', JSON.stringify(userData), {
    expires: new Date(new Date().setDate(new Date().getDate() + 90))
  })
  const newCookieName = environment.envName === 'development' ? "su_dev" : environment.envName === 'staging' ? "su_staging" : "su"
  cookie.put(newCookieName, JSON.stringify(userData), {
    expires: new Date(new Date().setDate(new Date().getDate() + 90)),
    domain: '.sunstone.in',
  })

  return
}

export const deleteUserCookie = (cookie: CookieService) => {
  cookie.remove('psu')
  cookie.remove('psu', {domain: '.sunstone.in'})
  cookie.remove('alu')
  cookie.remove('alu', {domain: '.sunstone.in'})
  const newCookieName = environment.envName === 'development' ? "su_dev" : environment.envName === 'staging' ? "su_staging" : "su"
  cookie.remove(newCookieName)

  return
}
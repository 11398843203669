/**
 * @deprecated use TRACKING_PLATFORMS instead
 */
export type TrackerPlatforms = 'ga' | 'we' | 'se' | 'mo' | 'flutter';
export enum TRACKING_PLATFORMS {
  GA = 'ga',
  /**
   * Triggers for both Moengage and Webenage
   * will be depracated once moenagage is live
   */
  MO_WE = 'we',
  SE = 'se',
  MO = 'mo',
  FLUTTER = 'flutter',
}

/**
 * @deprecated
 */
export interface TrackerEventDeprecated {
  name: string;
  params?: object;
  action?: string;
  label?: ITrackerLabel;
  platform: TrackerPlatforms[];
}
export interface TrackingEvent {
  name: string;
  /**
   * Parameters to be sent in additional attributes of the event,
   * Not supported for GA
   */
  params?: any;
  /**
   * Denotes the user action, for example `click`, `tap`, `scroll`
   */
  action?: string;
  platform?: TRACKING_PLATFORMS[];
}

export type ITrackerLabel = 'userId' | string;
export type TrackerGenerator = (params: any) => TrackerEventDeprecated;

// @ts-ignore
/**
 * Legacy trackers
 */
export const Trackers: Record<string, TrackerEventDeprecated> = {
  testStepTotal: {name: 'test_step_total', platform: ['ga', 'se']},
  testSummary: {name: 'test_summary', platform: ['ga', 'se']},
  finishTest: {name: 'finish_test', platform: ['ga', 'se']},
  schedule_test: {name: 'schedule_test', platform: ['ga', 'se', 'flutter']},
  start_test: {name: 'start_test', platform: ['ga', 'se', 'flutter']},
  start_test_now: {name: 'start_test_now', platform: ['ga']},
  resume_test: {name: 'resume_test', platform: ['ga', 'se']},
  test_started: {name: 'test_started', platform: ['ga', 'se']},
  test_started_no_camera: {name: 'test_started_no_camera', platform: ['ga', 'se']},
  test_started_scholarship_feb_2022: {
    name: 'test_started_scholarship_feb_2022',
    platform: ['ga', 'se', 'we'],
  },
  test_submitted: {name: 'test_submitted', platform: ['ga', 'se']},
  test_dialog_cancelled: {name: 'test_dialog_cancelled', platform: ['ga']},
  submit_click_dialog_open: {
    name: 'submit_click_dialog_open',
    platform: ['ga'],
  },
  test_tab_change: {name: 'test_tab_change', platform: ['ga', 'se']},
  reScheduledTest: {name: 're-scheduled-test', platform: ['ga', 'se']},
  sample_test_dialog_open: {
    name: 'sample_test_dialog_open',
    platform: ['ga'],
  },
  sample_test_dialog_close: {
    name: 'sample_test_dialog_close',
    platform: ['ga'],
  },
  sample_test_1: {name: 'sample_test_1', platform: ['ga', 'se']},
  sample_test_2: {name: 'sample_test_2', platform: ['ga', 'se']},
  sample_test_3: {name: 'sample_test_3', platform: ['ga', 'se']},
  sample_test_4: {name: 'sample_test_4', platform: ['ga', 'se']},
  sample_test_5: {name: 'sample_test_5', platform: ['ga', 'se']},
  all_sample_test: {name: 'all_sample_test', platform: ['ga', 'se']},
  linkedin: {name: 'LinkedIn', platform: ['ga', 'we']},
  Interaction_Video_Play: {
    name: 'Interaction_Video_Play',
    platform: ['ga', 'we', 'se'],
  },
  Interaction_Video_Paused: {
    name: 'Interaction_Video_Paused',
    platform: ['ga', 'we', 'se'],
  },
  Interaction_Video_Play_Time: {
    name: 'Interaction_Video_Play_Time',
    platform: ['ga', 'we', 'se'],
  },
  Hub_Application_Status_dropdown: {
    name: 'Hub_Application_Status_dropdown',
    platform: ['ga', 'we'],
  },
  // doc upload revamp events:
  status_expanded: {name: 'status_expanded', platform: ['ga']},
  status_collapsed: {name: 'status_collapsed', platform: ['ga']},
  click_call_1: {name: 'click_call_1', label: 'userId', platform: ['ga', 'se']},
  click_whatsapp: {name: 'click_whatsapp', platform: ['ga', 'se']},
  know_more: {name: 'know_more', platform: ['ga']},
  click_call_main: {name: 'click_call_main', platform: ['ga', 'se']},
  hub_logout: {name: 'hub_logout', platform: ['ga', 'se']},
  faq_expand: {name: 'faq_expand', platform: ['ga']},
  faq_collapsed: {name: 'faq_collapsed', platform: ['ga']},
  slide_expert_tips: {name: 'slide_expert_tips', platform: ['ga']},
  link_clicked: {name: 'link_clicked', platform: ['ga']},
  join_clicked: {name: 'join_clicked', platform: ['ga', 'se']},
  document_upload_start: {name: 'document_upload_start', platform: ['ga', 'se']},
  document_share_whatsapp: {name: 'document_share_whatsapp', platform: ['ga', 'se']},
  all_docs_submitted: {name: 'all_docs_submitted', platform: ['ga', 'se']},
  cancel_click: {name: 'cancel_click', platform: ['ga']},
  resume_doc_upload: {name: 'resume_doc_upload', platform: ['ga']},
  doc_upload_pop_closed: {name: 'doc_upload_pop_closed', platform: ['ga']},
  scholarship_test_register_feb_2022: {name: 'scholarship_test_register_feb_2022', platform: ['ga', 'se', 'we']},
  ug_scholarships_subscribe: {name: 'ug_scholarships_subscribe', platform: ['ga', 'se', 'we']},
  step1_overlay_next: {name: 'step1_overlay_next', platform: ['ga']},
  step1_overlay_skip: {name: 'step1_overlay_skip', platform: ['ga']},
  step1_hq_completed: {name: 'step1_hq_completed', platform: ['ga']},
  step2_overlay_next: {name: 'step2_overlay_next', platform: ['ga']},
  step2_overlay_skip: {name: 'step2_overlay_skip', platform: ['ga']},
  step2_3_pop_up_closed: {name: 'step2_3_pop_up_closed', platform: ['ga']},
  step2_acad_details_completed: {name: 'step2_acad_details_completed', platform: ['ga']},
  add_photo_clicked: {name: 'add_photo_clicked', platform: ['ga', 'se']},
  photo_uploaded: {name: 'photo_uploaded', platform: ['ga', 'se']},
  google_clicked: {name: 'google_clicked', platform: ['ga']},
  linkedin_clicked: {name: 'linkedin_clicked', platform: ['ga']},
  fb_clicked: {name: 'fb_clicked', platform: ['ga']},
  google_synced: {name: 'google_synced', platform: ['ga']},
  linkedin_synced: {name: 'linkedin_synced', platform: ['ga']},
  fb_synced: {name: 'fb_synced', platform: ['ga']},
  entered_name: {name: 'entered_name', platform: ['ga']},
  entered_email: {name: 'entered_email', platform: ['ga']},
  same_WA_unchecked: {name: 'same_WA_unchecked', platform: ['ga']},
  same_WA_checked: {name: 'same_WA_checked', platform: ['ga']},
  state_selected: {name: 'state_selected', platform: ['ga']},
  city_selected: {name: 'city_selected', platform: ['ga']},
  family_income_selected: {name: 'family_income_selected', platform: ['ga']},
  category_selected: {name: 'category_selected', platform: ['ga']},
  step2_personal_details_submitted: {name: 'step2_personal_details_submitted', platform: ['ga']},

  step4_program_submitted: {name: 'step4_program_submitted', platform: ['ga']},
  step5_overlay_confirm: {name: 'step5_overlay_confirm', platform: ['ga']},
  change_location_clicked: {name: 'change_location_clicked', platform: ['ga']},
  location_selected: {name: 'location_selected', platform: ['ga']},
  popular_city_name_selected: {name: 'popular_city_name_selected', platform: ['ga']},
  show_me_all: {name: 'show_me_all', platform: ['ga']},
  step5_continue: {name: 'step5_continue', platform: ['ga']},
  campus_details_clicked: {name: 'campus_details_clicked', platform: ['ga']},
  step6_campus_submitted: {name: 'step6_campus_submitted', platform: ['ga']},
  campus_skipped: {name: 'campus_skipped', platform: ['ga']},
  Q1_answered: {name: 'Q1_answered', platform: ['ga']},
  Q2_answered: {name: 'Q2_answered', platform: ['ga']},
  s7_payment_plan_submitted: {name: 's7_payment_plan_submitted', platform: ['ga']},

  //  Profile Events
  Profile_click_icon: {name: 'Profile_click_icon', platform: ['ga', 'se', 'mo']},
  //   Profile_edit_picture: {name: 'Profile_edit_picture', platform: ['ga', 'se']},
  Profile_edit_location: {name: 'Profile_edit_location', platform: ['ga', 'se', 'mo']},
  // Profile_Aadhaar_upload: {name: 'Profile_Aadhaar_upload', platform: ['ga', 'se']},
  // Profile_Aadhaar_view: {name: 'Profile_Aadhaar_view', platform: ['ga', 'se']},
  // Profile_Aadhaar_remove: {name: 'Profile_Aadhaar_remove', platform: ['ga', 'se']},
  Profile_Certifications_add: {name: 'Profile_Certifications_add', platform: ['ga', 'se', 'mo']},
  // Profile_Certifications_save: {name: 'Profile_Certifications_save', platform: ['ga', 'se']},
  // Profile_Achievements_date: {name: 'Profile_Achievements_date', platform: ['ga', 'se']},
  Profile_Certifications_remove: {name: 'Profile_Certifications_remove', platform: ['ga', 'se', 'mo']},
  Profile_Skill_add: {name: 'Profile_Skill_add', platform: ['ga', 'se', 'mo']},
  Profile_Skill_save: {name: 'Profile_Skill_save', platform: ['ga', 'se', 'mo']},
  Profile_Work_ex_add: {name: 'Profile_Work_ex_add', platform: ['ga', 'se', 'mo']},
  // Profile_Linkedin_add: {name: 'Profile_Linkedin_add', platform: ['ga', 'se']},
  Profile_Work_ex_save: {name: 'Profile_Work_ex_save', platform: ['ga', 'se', 'mo']},
  // Application form events
  Eligibility_Subject_select: {name: 'Subject_select', platform: ['ga', 'se']},
  Program_select: {name: 'Program_select', platform: ['ga', 'se']},
  applicationFormParentRelationshipSelect: {
    name: 'application_form_parent_relationship_select',
    platform: ['ga', 'se'],
  },
  applicationFormParentNumberInput: {
    name: 'application_form_parent_number_input',
    platform: ['ga', 'se'],
  },
  // Admission test events
  Camera_settings_Request: {name: 'Camera_settings_Request', platform: ['ga', 'se']},
  Browser_message_Accept: {name: 'Browser_message_Accept', platform: ['ga', 'se']},
  Browser_message_Deny: {name: 'Browser_message_Deny', platform: ['ga', 'se']},
  // Admission test events
  Document_Upload_Screen_Loaded: {name: 'Document_Upload_Screen_Loaded', platform: ['ga', 'se']},
  PI_Skip_Document_Upload_Screen_Loaded: {name: 'PI_Skip_Document_Upload_Screen_Loaded', platform: ['ga', 'se']},
  Quiz_Zone_click: {name: 'Quiz_Zone_click', platform: ['ga', 'se']},
  Quiz_Zone_timespent_seconds: {name: 'Quiz_Zone_timespent_seconds', platform: ['ga', 'se']},
  Home_click_icon: {name: 'Home_click_icon', platform: ['ga', 'se']},
  campus_choice_not_asked: {name: 'campus_choice_not_asked', platform: ['ga', 'se']},
};

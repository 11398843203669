import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {CookieService} from 'ngx-cookie';
import {getUserDetailsFromWebviewContextOrCookie} from '../services/auth.helpers';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId: any, public cookie: CookieService) {}

  userId = null;

  fireSocialEvent(category, label): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.userId) {
        let userData = getUserDetailsFromWebviewContextOrCookie(this.cookie);

        if (userData) {
          if (userData) {
            this.userId = (userData as any)?.id;
          }
        }
      }

      setTimeout(() => {
        try {
          const eventObj = {};
          eventObj['event_category'] = category;
          eventObj['event_label'] = label;
          eventObj['event_action'] = 'error';

          if (window['gtag']) {
            console.log('ERROR EVENT FIRED GA', category, eventObj);
            window['gtag']('event', eventObj['event_action'], eventObj);
          }
        } catch (e) {
          console.log('error in firing social event', e);
        }
      }, 1000);
    }
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          this.fireSocialEvent('hub_client_error', error?.error?.message);
        } else {
          const errorMsg = `Error Code: ${error.status},  Message: ${error.message}, userId: ${this.userId}`;
          this.fireSocialEvent('hub_api_error', errorMsg);
        }
        return throwError(error);
      }),
    );
  }
}

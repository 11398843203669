import {StageNames, TaskNames} from './stage-resolver.enums';
import {LeadJourney, Stage, StageGroup} from './lead-journey.types';

export function areAllTasksComplete(stage: Stage): boolean {
  return stage.tasks.every((task) => task.complete);
}

function activeStage(stageGroup: StageGroup, skipIncomplete = true): StageNames {
  // if all tasks of a stage are complete in a group, we can show the next stage
  for (const stage of stageGroup.stages) {
    if (!stage.complete) {
      if (areAllTasksComplete(stage) && skipIncomplete) {
        continue;
      }
      return stage.name;
    }
  }
  return stageGroup.stages[stageGroup.stages.length - 1].name;
  // return stageGroup.stages.find((stage) => !stage.complete)?.name;
}

export function getCurrentStage(leadJourney: LeadJourney, skipIncomplete = true): StageNames {
  for (const stageGroup of leadJourney) {
    if (stageGroup.complete && leadJourney.indexOf(stageGroup) === leadJourney.length - 1) {
      // all stages complete, return last stage
      return stageGroup.stages[stageGroup.stages.length - 1].name;
    } else if (!stageGroup.complete) {
      return activeStage(stageGroup, skipIncomplete);
    }
  }
}

export function isStageComplete(stageName: StageNames, leadJourney: LeadJourney): boolean {
  for (const stageGroup of leadJourney) {
    const foundStage = stageGroup.stages.find((stage) => stage.name === stageName);
    if (foundStage) {
      return foundStage.complete;
    }
  }
  return false;
}

export function isStagePresent(stageName: StageNames, leadJourney: LeadJourney): boolean {
  for (const stageGroup of leadJourney) {
    const foundStage = stageGroup.stages.find((stage) => stage.name === stageName);
    if (foundStage) {
      return true;
    }
  }
  return false;
}

export function getStageTasks(stageName: StageNames, leadJourney: LeadJourney): TaskNames[] {
  for (const stageGroup of leadJourney) {
    const foundStage = stageGroup.stages.find((stage) => stage.name === stageName);
    if (foundStage) {
      return foundStage.tasks.map((task) => task.name);
    }
  }
  return [];
}

export function getIncompleteStageTasks(stageName: StageNames, leadJourney: LeadJourney): TaskNames[] {
  for (const stageGroup of leadJourney) {
    const foundStage = stageGroup.stages.find((stage) => stage.name === stageName);
    if (foundStage) {
      return foundStage.tasks.filter((task) => !task.complete).map((task) => task.name);
    }
  }
  return [];
}

export function getStage(stageName: StageNames, leadJourney: LeadJourney): Stage {
  for (const stageGroup of leadJourney) {
    const foundStage = stageGroup.stages.find((stage) => stage.name === stageName);
    if (foundStage) {
      return foundStage;
    }
  }
  return null;
}

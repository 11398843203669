import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {lastValueFrom, ReplaySubject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {LeadJourney} from './lead-journey.types';
import {select, Store} from '@ngrx/store';
import {AdmissionState} from '../../shared/admission.reducer';
import {selectStudentData} from '../../shared/admission.selectors';
import {take} from 'rxjs/operators';
import {StageNames, TaskNames} from './stage-resolver.enums';

@Injectable({
  providedIn: 'root',
})
export class StageResolverService {
  leadJourney: LeadJourney;
  leadJourneyHistory: {name: TaskNames | StageNames; timestamp: Date; active: boolean}[];
  completedHistory: Set<TaskNames | StageNames>;
  leadJourneySub$: ReplaySubject<LeadJourney> = new ReplaySubject<LeadJourney>(1);

  constructor(private http: HttpClient, private store: Store<AdmissionState>) {}

  async fetchLeadJourney(force = false): Promise<void> {
    if (this.leadJourney && !force) {
      return;
    }
    const [journeyRes]: any = await Promise.all([
      lastValueFrom(this.http.get(`${environment.urls.host}v2/lead/journey`)),
      this.fetchLeadJourneyHistory(),
    ]);
    this.updateLeadJourney(journeyRes.data);
  }

  async fetchLeadJourneyHistory(): Promise<void> {
    const historyRes: any = await lastValueFrom(this.http.get(`${environment.urls.host}v2/lead/journey/lead/history`));
    this.leadJourneyHistory = historyRes.data;
    this.completedHistory = new Set(this.leadJourneyHistory.filter((item) => item.active).map((item) => item.name));
  }

  isComplete(name: TaskNames | StageNames): boolean {
    return this.completedHistory.has(name);
    // return this.leadJourneyHistory.some((history) => history.name === name && history.active);
  }

  updateLeadJourney(journey: LeadJourney): void {
    this.leadJourneySub$.next(journey);
    this.leadJourney = journey;
  }

  async detectChanges(): Promise<void> {
    const studentData = await lastValueFrom(this.store.pipe(select(selectStudentData), take(1)));
    await lastValueFrom(this.http.get(`${environment.urls.host}v2/lead/journey/detect-changes/${studentData.id}`));
    await this.fetchLeadJourney(true);
  }
}

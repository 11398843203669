import {DOCUMENT, isPlatformBrowser, Location, PlatformLocation} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, NgZone, Optional, PLATFORM_ID} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {REQUEST, RESPONSE} from '@nguniversal/express-engine/tokens';
import {Response} from 'express';
import {CookieService} from 'ngx-cookie';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {MODULE_ROUTE_MAP} from '../admission-new/modules/lead-journey/stage-resolver.constants';
import {StageResolverService} from '../admission-new/modules/lead-journey/stage-resolver.service';
import {getCurrentStage} from '../admission-new/modules/lead-journey/stage-resolver.utils';
import {clearAdmissionStore} from '../admission-new/shared/admission.actions';
import {NativeActions, Urls} from '../admission-new/shared/admission.constants';
import {AdmissionState} from '../admission-new/shared/admission.reducer';
import {ProgramService} from '../admission-new/shared/services/program.service';
import * as global from '../global';
import * as dashboardData from '../jsonData/dashboardData.json';
import {Moengage} from '../shared/moengage.config';
import {
  EligibleYear,
  IApiResponse,
  ProfileCompletion,
  WebviewAction,
  WEBVIEW_USER_AGENT_NAME,
  WEBVIEW_USER_AGENT_NAME_IOS,
  ADMISSION_PENDING_STATE_COOKIE,
} from '../shared/shared.constants';
import {TRACKING_PLATFORMS} from '../shared/tracker.constants';
import {
  getUserDetailsFromWebviewContext,
  getUserDetailsFromWebviewContextOrCookie,
  getWebviewContext,
  updateUserDetailsIntoCookie,
  deleteUserCookie,
} from './auth.helpers';
import {StateCityService} from './state-city.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  hostUrl;
  s3Url;
  miscellaneousUrl;
  currentpage;
  userDetails;
  trafficSource = '';
  apiheader = {
    Authorization: '',
  };
  userLoggedin;
  gapi: any;
  auth2: any;
  showLoader = false;
  showInternalLoader = false;
  studentData;
  cvData;
  profileFormState: any = {
    form1: 'expand',
    form2: 'collapse',
    form3: 'collapse',
    form4: 'collapse',
    form5: 'collapse',
    form6: 'collapse',
    form7: 'collapse',
    form8: 'collapse',
    form9: 'collapse',
  };
  unreadCount;
  calenderData = global.calenderData;
  redirectUri;
  issue;
  pendingState;
  admissionHost;
  showQueryPopup = false;
  queryPopupData: any = {not_sunstone_email: false};
  how_come_know = {
    'Saw an ad': ['Facebook', 'Instagram', 'Google', 'YouTube', 'Others'],
    'Recommended by someone': ['Friend', 'Family Member', 'Someone Else'],
    'Newspaper article': [],
    'Found it myself while looking for B-Schools online': [],
    'Through a blog': [],
    'Through a consultant': [],
    'Social media channel': [],
    'Saw a YouTube Video': [],
    Others: [],
  };
  faqlist;
  faqstate;
  subtopiclist = [];
  userType;
  utmParams: any = {};
  timespent;
  scroll30fired;
  scroll50fired;
  scroll85fired;
  scroll100fired;
  dashboardData = dashboardData['default'];
  campusImages: any = [];
  near_pg_data: any = [];
  nearest_railway;
  nearest_airport;
  faqs: any = [];
  meet_seniors: any = [];
  batchMatesList: any = [];
  handbookUrl: any = '';
  //pap data no usage here
  pap_withoutscoller_k = [
    '/assets/images/agreement/Annexure_1.jpeg',
    '/assets/images/agreement/Annexure_1_2.jpeg',
    '/assets/images/agreement/Annexure_1_3.jpeg',
    '/assets/images/pap_125_k/PAP_125_K1.jpeg',
    '/assets/images/pap_125_k/PAP_125_K2.jpeg',
    '/assets/images/pap_125_k/PAP_125_K3.jpeg',
    '/assets/images/pap_125_k/PAP_125_K4.jpeg',
    '/assets/images/pap_125_k/PAP_125_K5.jpeg',
    '/assets/images/pap_125_k/PAP_125_K6.jpeg',
    '/assets/images/pap_125_k/PAP_125_K7.jpeg',
    '/assets/images/pap_125_k/PAP_125_K8.jpeg',
    '/assets/images/pap_125_k/PAP_125_K9.jpeg',
  ];
  // pap_withscoller_k = [
  //   '/assets/images/agreement/Annexure_1.jpeg',
  //   '/assets/images/agreement/Annexure_1_2.jpeg',
  //   '/assets/images/agreement/Annexure_1_3.jpeg',
  //   '/assets/images/pap_65k/pap_65k1.jpeg',
  //   '/assets/images/pap_65k/pap_65k2.jpeg',
  //   '/assets/images/pap_65k/pap_65k3.jpeg',
  //   '/assets/images/pap_65k/pap_65k4.jpeg',
  //   '/assets/images/pap_65k/pap_65k5.jpeg',
  //   '/assets/images/pap_65k/pap_65k6.jpeg',
  //   '/assets/images/pap_65k/pap_65k7.jpeg',
  //   '/assets/images/pap_65k/pap_65k8.jpeg',
  //   '/assets/images/pap_65k/pap_65k9.jpeg',
  // ]
  mbg_withscoller_k = [
    '/assets/images/agreement/Annexure_1.jpeg',
    '/assets/images/agreement/Annexure_1_2.jpeg',
    '/assets/images/agreement/Annexure_1_3.jpeg',
    '/assets/images/mbg_65k/MBG1.jpeg',
    '/assets/images/mbg_65k/MBG2.jpeg',
    '/assets/images/mbg_65k/MBG3.jpeg',
    '/assets/images/mbg_65k/MBG4.jpeg',
    '/assets/images/mbg_65k/MBG5.jpeg',
    '/assets/images/mbg_65k/MBG6.jpeg',
    '/assets/images/mbg_65k/MBG7.jpeg',
  ];
  // mbg_withoutcoller_k = [
  //   '/assets/images/agreement/Annexure_1.jpeg',
  //   '/assets/images/agreement/Annexure_1_2.jpeg',
  //   '/assets/images/agreement/Annexure_1_3.jpeg',
  //   '/assets/images/mbg_255k/MBG_255K1.jpeg',
  //   '/assets/images/mbg_255k/MBG_255K2.jpeg',
  //   '/assets/images/mbg_255k/MBG_255K3.jpeg',
  //   '/assets/images/mbg_255k/MBG_255K4.jpeg',
  //   '/assets/images/mbg_255k/MBG_255K5.jpeg',
  //   '/assets/images/mbg_255k/MBG_255K6.jpeg',
  //   '/assets/images/mbg_255k/MBG_255K7.jpeg',
  // ]
  pap_detail_status = false;
  see_agreement_loader = false;
  agreestep = 2;
  papstart = false;
  slide_image = [];
  sliderPopup = false;
  window = window;
  offerDetails;
  offerQna;
  private _loadedLibraries: {[url: string]: ReplaySubject<any>} = {};

  //admission-test page variables
  testDuration = 30;
  admissionUserdetails: any = {};
  relationArr = ['Father', 'Mother', 'Sister', 'Brother'];
  /**
   * @deprecated use from `schedule-slot-selector.constants` instead
   */
  public timelist = [
    {value: '00:00:00', show: '12AM-1AM'},
    {value: '01:00:00', show: '1AM-2AM'},
    {value: '02:00:00', show: '2AM-3AM'},
    {value: '03:00:00', show: '3AM-4AM'},
    {value: '04:00:00', show: '4AM-5AM'},
    {value: '05:00:00', show: '5AM-6AM'},
    {value: '06:00:00', show: '6AM-7AM'},
    {value: '07:00:00', show: '7AM-8AM'},
    {value: '08:00:00', show: '8AM-9AM'},
    {value: '09:00:00', show: '9AM-10AM'},
    {value: '10:00:00', show: '10AM-11AM'},
    {value: '11:00:00', show: '11AM-12PM'},
    {value: '12:00:00', show: '12PM-1PM'},
    {value: '13:00:00', show: '1PM-2PM'},
    {value: '14:00:00', show: '2PM-3PM'},
    {value: '15:00:00', show: '3PM-4PM'},
    {value: '16:00:00', show: '4PM-5PM'},
    {value: '17:00:00', show: '5PM-6PM'},
    {value: '18:00:00', show: '6PM-7PM'},
    {value: '19:00:00', show: '7PM-8PM'},
    {value: '20:00:00', show: '8PM-9PM'},
    {value: '21:00:00', show: '9PM-10PM'},
    {value: '22:00:00', show: '10PM-11PM'},
    {value: '23:00:00', show: '11PM-12AM'},
  ];

  formloader = false;
  stepform = 1;
  registerform: any = {}; //sample form
  timeToTest;
  eligibleYear = EligibleYear;
  truecallerInterval: Subscription;
  user_exp: any = {
    TEST_EXP: ['1', '2'],
  };
  programList;
  programDict: object = {};
  onlineCampusDict: object = {};
  onlineCampus = [];
  isOnlineStudent = false;
  pixelLoaded = false;
  // stepformHistory: number;
  specialisation_list = [];
  // currentDomain;
  paidPayments = [];
  paidAmount = 0;
  showPaymentBox = false;
  isAcceptedBefore: boolean;

  constructor(
    @Optional() @Inject(REQUEST) public req: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private location: Location,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    public cookie: CookieService,
    private router: Router,
    public History: PlatformLocation,
    private dom: DomSanitizer,
    @Inject(DOCUMENT) private readonly document: any,
    private programService: ProgramService,
    private store: Store<AdmissionState>,
    @Optional() @Inject(RESPONSE) private res: Response,
    private stageResolverService: StageResolverService,
    private stateCityService: StateCityService,
  ) {
    const self = this;
    this.stateCityService.getStateList().subscribe();
    window.addEventListener('flutterInAppWebViewPlatformReady', function (event) {
      window[WEBVIEW_USER_AGENT_NAME]?.callHandler?.('getUser', null).then((_user) => {
        const userDetailsFromWebviewContext = getUserDetailsFromWebviewContext(_user);
        if (userDetailsFromWebviewContext && Boolean(userDetailsFromWebviewContext?.session_key)) {
          // self.cookie && self.cookie.put('alu', JSON.stringify(userDetailsFromWebviewContext), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
          //   domain: '.sunstone.in',
          // });
          // self.cookie.remove('psu', {
          //   domain: '.sunstone.in',
          // });
          // self.cookie && self.cookie.put('alu', JSON.stringify(userDetailsFromWebviewContext), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
          // });
          // self.cookie.remove('psu');

          updateUserDetailsIntoCookie(userDetailsFromWebviewContext, self.cookie);
        } else {
          const queryParams: WebviewAction = {
            action: NativeActions.LOGIN,
            onActionSuccess: `${window.location.origin}${Urls.APP_LOGIN}`,
            onActionFailure: `${window.location.origin}${Urls.APP_LOGIN}`,
          };
          window.location.href = `${window.location.origin}/action?nativeAction=${JSON.stringify(queryParams)}`;
        }
      });
    });
    this.hostUrl = environment.urls.host;
    this.admissionHost = environment.urls.admissionHost;
    this.miscellaneousUrl = environment.urls.misc;
    this.s3Url = environment.urls.cdn;

    // run on every page change complete
    router.events.subscribe((val) => {
      if (val.toString().split('(')[0] == 'NavigationEnd') {
        // set current page url
        window?.parent?.postMessage({type: 'redirect', data: {url: window.location.href}}, '*');
        const stringifyJson = JSON.stringify({type: 'redirect', data: {url: window.location.href}});
        getWebviewContext()?.new_screen?.(stringifyJson);
        getWebviewContext()?.callHandler?.('new_screen', stringifyJson);
        let pathname = window.location.pathname.split('/');
        this.currentpage = pathname[1];
        if (this.currentpage == '') {
          this.currentpage = 'home';
        } else if (this.currentpage == 'admission') {
          this.currentpage = pathname.slice(1, pathname.length).join('_');
        }

        // fetch utmparams
        window.location.search
          .substr(1, window.location.search.length)
          .split('&')
          .map((x) => x.split('='))
          .map((x) => {
            if (x[0]) {
              this.utmParams[x[0]] = x[1];
            }
          });

        // fetch resolve dashboard
        // if(this.userDetails.admission_year == '2021'){
        //   if(this.currentpage == 'dashboard'){
        //     this.router.navigate(['/student-dashboard'], {queryParamsHandling: 'preserve'});
        //   };
        // } else {
        //   if(this.currentpage == 'student-dashboard'){
        //     this.router.navigate(['/dashboard'], {queryParamsHandling: 'preserve'});
        //   };
        // };

        if (isPlatformBrowser(this.platformId)) {
          // set or get A/B User
          if (this.cookie.get('userType')) {
            this.userType = this.cookie.get('userType');
          } else {
            let randomNumber = Math.floor(Math.random() * 2) + 1;
            this.userType = randomNumber == 1 ? 'A' : 'B';
            this.cookie.put('userType', this.userType, {
              expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            });
          }

          // check if student then freshdesk run code
          if (this.userDetails && this.userDetails.role == 'student') {
            setTimeout(() => {
              // let fdCode = document.createElement('script');
              // fdCode.innerHTML = `window.fwSettings={
              //   'widget_id':82000003937
              //   };
              //   !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}();`;
              // document.body.appendChild(fdCode);
              // let fdScript = document.createElement('script');
              // fdScript.src = 'https://ind-widget.freshworks.com/widgets/82000003937.js';
              // document.body.appendChild(fdScript);
            }, 2000);
          }
        }
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        let windowscrollmax;
        let windowscroll30;
        let windowscroll50;
        let windowscroll85;
        document.addEventListener('scroll', () => {
          if (!windowscrollmax) {
            windowscrollmax = document.body.scrollHeight - window.innerHeight;

            windowscroll30 = windowscrollmax * 0.3;
            windowscroll50 = windowscrollmax * 0.5;
            windowscroll85 = windowscrollmax * 0.85;
          }

          if (window.scrollY >= windowscroll30 && !this.scroll30fired) {
            this.fireSocialEvent('Scroll_30', '', '', ['ga', 'we'], {
              pagename: this.currentpage,
            });
            this.scroll30fired = true;
          }
          if (window.scrollY >= windowscroll50 && !this.scroll50fired) {
            this.fireSocialEvent('Scroll_50', '', '', ['ga', 'we'], {
              pagename: this.currentpage,
            });
            this.scroll50fired = true;
          }
          if (window.scrollY >= windowscroll85 && !this.scroll85fired) {
            this.fireSocialEvent('Scroll_85', '', '', ['ga', 'we'], {
              pagename: this.currentpage,
            });
            this.scroll85fired = true;
          }
          if (window.scrollY >= windowscrollmax && !this.scroll100fired) {
            this.fireSocialEvent('Scroll_100', '', '', ['ga', 'we'], {
              pagename: this.currentpage,
            });
            this.scroll100fired = true;
          }
        });
      }, 1000);

      this.saveSourceFromReferrer();

      window.addEventListener('online', () => {
        this.openToast('Hurray! You are online now.', 'Ok');
      });
      window.addEventListener('offline', () => {
        this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
      });
    }

    setTimeout(() => {
      document.domain = 'sunstone.in';
    }, 0);
  }

  stopScroll() {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  startScroll() {
    document.getElementsByTagName('body')[0].style.overflow = 'visible';
  }

  saveSourceFromReferrer() {
    let referrerUrl;
    if (sessionStorage.trafficSource) {
      this.trafficSource = sessionStorage.trafficSource;
    } else {
      referrerUrl = document.referrer;
      if (referrerUrl) {
        if (referrerUrl.indexOf('google') > -1 || referrerUrl.indexOf('bing') > -1) {
          this.trafficSource = 'Organic';
        } else if (referrerUrl.indexOf('sunstone') > -1) {
          this.trafficSource = 'Direct';
        } else {
          this.trafficSource = 'Miscellaneous';
        }
      } else {
        this.trafficSource = 'Direct';
      }
      sessionStorage.trafficSource = this.trafficSource;
    }
  }

  uploadutmParametersToServer() {
    if (!this.isBrowser()) {
      return;
    }
    if (!this.utmParams.utm_source) {
      this.utmParams.utm_source = this.trafficSource;
    }
    if (!sessionStorage.utm_set) {
      let sendutm = {};
      (sendutm['source'] = this.utmParams.utm_source),
        (sendutm['medium'] = this.utmParams.utm_medium),
        (sendutm['campaign'] = this.utmParams.utm_campaign);
      this.postApi('api/user/utm-mapping', sendutm, this.apiheader)
        .then((data) => {
          if (data['success']) {
            sessionStorage.setItem('utm_set', 'true');
          }
        })
        .catch((err) => {
          console.log('Error updating UTM parameters');
        });
    }
  }

  console(datap) {
    console.log(datap);
  }

  weLoginUser(user_data) {
    if (this.isBrowser()) {
      window['webengage'] && window['webengage'].user.login(user_data['id']);
      Moengage()?.add_unique_user_id(user_data['id']);
    }
  }

  /**
   * @deprecated use TrackerService.triggerTrackingEvent instead
   */
  fireSocialEvent(category, label, action, platform, params?) {
    if (this.isBrowser()) {
      setTimeout(() => {
        try {
          const isPlatformWebview = this.isFlutterWebView();
          let eventObj = {};
          eventObj['event_category'] = category;
          eventObj['event_label'] = label;
          eventObj['event_action'] = action;
          eventObj['userType'] = this.userType;

          if (platform.includes('ga') && window['gtag']) {
            console.log('SOCIAL EVENT FIRED GA', category, eventObj, this.userDetails?.id);
            window['gtag']('event', eventObj['event_action'], eventObj);
          }
          // replace weengage with Moengage
          // 'or' check because old tracker has platform 'we' and 'mo' for new trackers
          if (platform.includes('we')) {
            let utm_params = {};
            if (this.utmParams.utm_source) {
              utm_params['utm_source'] = this.utmParams.utm_source;
            }

            if (this.utmParams.utm_medium) {
              utm_params['utm_medium'] = this.utmParams.utm_medium;
            }
            if (this.utmParams.utm_campaign) {
              utm_params['utm_campaign'] = this.utmParams.utm_campaign;
            }

            params = {...params, ...utm_params, isPlatformWebview};
            console.log('SOCIAL EVENT FIRED WEBENGAGE', category, params);
            window['webengage']?.track?.(eventObj['event_category'], params || {});
            console.log('SOCIAL EVENT FIRED MOBENGAGE', category, params);
            Moengage()?.track_event(eventObj['event_category'], params || {});
          }
          if (platform.includes(TRACKING_PLATFORMS.MO)) {
            let utm_params = {};
            if (this.utmParams.utm_source) {
              utm_params['utm_source'] = this.utmParams.utm_source;
            }

            if (this.utmParams.utm_medium) {
              utm_params['utm_medium'] = this.utmParams.utm_medium;
            }
            if (this.utmParams.utm_campaign) {
              utm_params['utm_campaign'] = this.utmParams.utm_campaign;
            }

            params = {...params, ...utm_params, isPlatformWebview};
            console.log('SOCIAL EVENT FIRED MOBENGAGE', category, params);
            Moengage()?.track_event(eventObj['event_category']?.toUpperCase?.(), params || {});
          }

          if (platform.includes('se')) {
            let apiObj = {isPlatformWebview};
            if (this.userDetails) {
              const userData = getUserDetailsFromWebviewContextOrCookie(this.cookie);
              // let userData = this.cookie.get('alu');
              // if (!userData) {
              //   userData = this.cookie.get('psu');
              // }
              // userData = JSON.parse(userData);
              apiObj['user_id'] = userData['id'];
              apiObj['event_name'] = eventObj['event_category'];
              apiObj['event_data'] = params;
              this.postApi('lead/saveUserEvents', apiObj, {})
                .then((data) => {
                  console.log('SOCIAL EVENT FIRED SUNSTONE', category, params, this.userDetails?.id);
                })
                .catch((err) => {
                  console.log('SOCIAL EVENT FIRED SUNSTONE ERROR', err);
                });
            } else {
              console.log('SOCIAL EVENT FIRED SUNSTONE (NO LOGIN)', category, params);
            }
          }
        } catch (e) {
          console.log('error in firing social event', e);
        }
      }, 500);
    }
  }

  openLink(link) {
    window.open(link, '_blank');
  }

  fetchFaqlist() {
    this.showLoader = true;
    this.getApi('student/getMainTopics', this.apiheader)
      .then((resp: any) => {
        if (resp.success) {
          this.faqlist = resp.data;
          this.faqstate = Array(this.faqlist.length).fill('collapse');
          this.faqstate[0] = 'expand';
        } else {
          this.openToast("Error in Fetching FAQ's List", 'Ok');
        }
        this.showLoader = false;
      })
      .catch((err) => {
        this.openToast(
          (err && err.error && err.error['message']) || 'Something went wrong. Please try again later.',
          'Ok',
        );
        console.log(err);
        this.showLoader = false;
      });
  }

  get showNotifyPopup() {
    if (this.isBrowser()) {
      if (!window['localStorage'].onp) {
        return true;
      } else {
        let idArr = JSON.parse(window['localStorage'].onp);
        if (idArr.includes(this.studentData.id)) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  showSample(url) {
    let urlSplit = url.split('/')[url.split('/').length - 1];
    return urlSplit;
  }

  redirectTo(url) {
    this.router.navigate([url], {queryParamsHandling: 'preserve'});
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }

  typeOfRole(role) {
    if (role) {
      role = role.split('-');
      for (let i = 0; i < role.length; i++) {
        role[i] = role[i].charAt(0).toUpperCase() + role[i].substr(1, role[i].length - 1);
      }
      role = role.join(' ');
    }

    return role;
  }

  googleInit() {
    this.fireSocialEvent('Login_With_Google', '', '', ['ga', 'we']);

    let popupUrl =
      'https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&include_granted_scopes=true&response_type=code&client_id=185309741028-8ljbd1eqq4lgnur7kf917k4hb2vr35ms.apps.googleusercontent.com&redirect_uri=' +
      window.location.origin;
    let popupHeight = 500;
    let popupWidth = 500;

    var centerWidth = (window.screen.width - popupWidth) / 2,
      centerHeight = (window.screen.height - popupHeight) / 2;

    window['popup'] = window.open(
      popupUrl,
      'Sunstone Hub Login',
      'width=500,height=500,left=' +
        centerWidth +
        ',top=' +
        centerHeight +
        ',resizable=yes,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no,status=yes',
    );
    return window['popup'];
  }

  checkAuth() {
    let self = this;
    return new Promise(async (resolve, reject) => {
      console.log('in checkAuth');
      // if (this.cookie && this.cookie.get('psu')) {
      //   this.userDetails = JSON.parse(this.cookie.get('psu'));
      //   this.apiheader['Authorization'] = 'Bearer ' + this.userDetails['session_key'];

      //   var USER_ID = this.userDetails.id;
      //   resolve(true);
      // } else {
      //   this.logoutUser();
      //   resolve(false);
      // }
      const userDetails = getUserDetailsFromWebviewContextOrCookie(self.cookie);
      if (userDetails) {
        self.userDetails = userDetails;
        self.apiheader['Authorization'] = 'Bearer ' + self.userDetails['session_key'];
        var USER_ID = self.userDetails.id;
        resolve(true);
      } else {
        self.logoutUser();
        resolve(false);
      }
    });
  }

  checkLoginOld() {
    let self = this;
    return new Promise(async (resolve, reject) => {
      console.log(
        '?????? INSIDE OLD LOGIN CHECK PROMISE: Get User',
        getWebviewContext()?.getUser || getWebviewContext()?.getUser,
      );
      console.log(
        '?????? RESPONSE FROM getWebviewContext() - checkLoginOld',
        getWebviewContext() && JSON.stringify(getWebviewContext()),
      );
      const user = getUserDetailsFromWebviewContextOrCookie(self.cookie);
      if (getWebviewContext()?.getUser) {
        console.log('?????? INSIDE OLD LOGIN FUNCTION FOUND');
        // const user = getUserDetailsFromWebviewContextOrCookie(
        //   getWebviewContext()?.getUser?.(),
        //   self.cookie,
        // );
        console.log('?????? INSIDE OLD LOGIN USER DATA: ', JSON.stringify(user));
        if (user) {
          console.log('?????? INSIDE IF');
          if (user.role?.includes?.('lead')) {
            console.log('?????? INSIDE USER ROLE', user.role);

            // self.cookie && self.cookie.put('alu', JSON.stringify(user), {
            //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
            //   domain: '.sunstone.in',
            // })
            // self.cookie.remove('psu', {
            //   domain: '.sunstone.in',
            // })
            // self.cookie && self.cookie.put('alu', JSON.stringify(user), {
            //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
            // })
            // self.cookie.remove('psu')

            updateUserDetailsIntoCookie(user, self.cookie);

            console.log('?????? COOKIE CHECK ALU', self.cookie.get('alu'));
            console.log('?????? COOKIE CHECK PSU', self.cookie.get('psu'));
            resolve(false);
          } else {
            resolve(true);
          }
        } else {
          console.log('?????? INSIDE ELSE');
          // open native login - > redirect to new url -> login action handling
          const queryParams: WebviewAction = {
            action: NativeActions.LOGIN,
            onActionSuccess: `${window.location.origin}${Urls.APP_LOGIN}`,
            onActionFailure: `${window.location.origin}${Urls.APP_LOGIN}`,
          };
          window.location.href = `${window.location.origin}/action?nativeAction=${JSON.stringify(queryParams)}`;
          resolve(false);
        }
      }

      // if student
      // if (this.cookie && this.cookie.get('psu')) {
      //   console.log('?????? INSIDE STUDENT ');
      //   this.userDetails = JSON.parse(this.cookie.get('psu'));
      //   this.apiheader['Authorization'] = 'Bearer ' + this.userDetails['session_key'];
      //   this.loginRedirectionStudent('check login old');
      //   resolve(false);
      // }
      if (user?.role == 'student') {
        console.log('?????? INSIDE STUDENT ');
        // this.userDetails = JSON.parse(this.cookie.get('psu'));
        self.userDetails = getUserDetailsFromWebviewContextOrCookie(self.cookie);
        self.apiheader['Authorization'] = 'Bearer ' + self.userDetails['session_key'];
        self.loginRedirectionStudent('check login old');
        resolve(false);
      }

      // if lead
      // else if (this.cookie && this.cookie.get('alu')) {
      //   console.log('?????? INSIDE LEAD');
      //   this.userDetails = JSON.parse(this.cookie.get('alu'));
      //   this.apiheader['Authorization'] = 'Bearer ' + this.userDetails['session_key'];
      //   const authToken = new window.URL(window['location'].href).searchParams.get('code'); // this token can be linkedin or google token
      //   if (authToken) {
      //     localStorage.setItem('auth_token', authToken);
      //     window.close();
      //   }
      //   resolve(false);
      // }
      else if (user?.role == 'lead') {
        console.log('?????? INSIDE LEAD');
        self.userDetails = getUserDetailsFromWebviewContextOrCookie(self.cookie);
        // this.userDetails = JSON.parse(this.cookie.get('alu'));
        self.apiheader['Authorization'] = 'Bearer ' + self.userDetails['session_key'];
        const authToken = new window.URL(window['location'].href).searchParams.get('code'); // this token can be linkedin or google token
        if (authToken) {
          localStorage.setItem('auth_token', authToken);
          window.close();
        }
        resolve(false);
      } else {
        console.log('?????? INSIDE ELSE - NO COOKIE FOUND');
        resolve(true);
      }
    });
  }

  checkLogin() {
    let self = this;
    return new Promise(async (resolve, reject) => {
      console.log('?????? INSIDE NEW LOGIN CHECK PROMISE: Get User', getWebviewContext()?.getUser);
      // if in webview then auto login user
      console.log(
        '?????? RESPONSE FROM getWebviewContext() - checkLogin',
        getWebviewContext() && JSON.stringify(getWebviewContext()),
      );
      if (getWebviewContext()?.getUser) {
        console.log('?????? INSIDE NEW LOGIN FUNCTION FOUND');
        const user = getUserDetailsFromWebviewContextOrCookie(self.cookie);
        console.log('?????? INSIDE NEW LOGIN USER DATA: ', JSON.stringify(user));
        if (user) {
          console.log('?????? INSIDE IF');

          // self.cookie && self.cookie.put('alu', JSON.stringify(user), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
          //   domain: '.sunstone.in',
          // });
          // self.cookie && self.cookie.put('psu', JSON.stringify(user), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
          //   domain: '.sunstone.in',
          // });
          // self.cookie && self.cookie.put('alu', JSON.stringify(user), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
          // });
          // self.cookie && self.cookie.put('psu', JSON.stringify(user), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
          // });

          updateUserDetailsIntoCookie(user, self.cookie);
          resolve(false);
        } else {
          console.log('?????? INSIDE ELSE');
          // open native login - > redirect to new url -> login action handling
          const queryParams: WebviewAction = {
            action: NativeActions.LOGIN,
            onActionSuccess: `${window.location.origin}${Urls.APP_LOGIN}`,
            onActionFailure: `${window.location.origin}${Urls.APP_LOGIN}`,
          };
          window.location.href = `${window.location.origin}/action?nativeAction=${JSON.stringify(queryParams)}`;
          resolve(false);
        }
      }

      // check user login
      // if (this.cookie && (this.cookie.get('psu') || this.cookie.get('alu'))) {
      //   const userCookie = this.cookie.get('psu') || this.cookie.get('alu');
      //   this.userDetails = JSON.parse(userCookie);
      //   this.apiheader['Authorization'] = 'Bearer ' + this.userDetails['session_key'];
      //   if (this.userDetails.role?.includes?.('student')) {
      //     this.loginRedirectionStudent('check login new');
      //   }
      //   resolve(false);
      // }
      const newUserDetails = getUserDetailsFromWebviewContextOrCookie(self.cookie);
      if (newUserDetails) {
        self.userDetails = newUserDetails;
        self.apiheader['Authorization'] = 'Bearer ' + self.userDetails['session_key'];
        if (self.userDetails.role?.includes?.('student')) {
          self.loginRedirectionStudent('check login new');
        }
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }

  checkAdmissionOld() {
    let self = this;
    return new Promise(async (resolve, reject) => {
      console.log('?????? 1 INSIDE OLD ADMISSION CHECK ANDROID: NAME', WEBVIEW_USER_AGENT_NAME);
      console.log('?????? 1 INSIDE OLD ADMISSION CHECK ANDROID: Get User', window?.[WEBVIEW_USER_AGENT_NAME]?.getUser);
      console.log('?????? 1 INSIDE OLD ADMISSION CHECK IOS: NAME', WEBVIEW_USER_AGENT_NAME_IOS);
      console.log('?????? 1 INSIDE OLD ADMISSION CHECK IOS: Get User', window?.[WEBVIEW_USER_AGENT_NAME_IOS]?.getUser);
      console.log(
        '?????? RESPONSE FROM getWebviewContext() - checkAdmissionOld',
        getWebviewContext() && JSON.stringify(getWebviewContext()),
      );
      if (getWebviewContext()?.getUser) {
        console.log('?????? 2 INSIDE OLD ADMISSION CHECK ANDROID: NAME', WEBVIEW_USER_AGENT_NAME);
        console.log(
          '?????? 2 INSIDE OLD ADMISSION CHECK ANDROID: Get User',
          window?.[WEBVIEW_USER_AGENT_NAME]?.getUser,
        );
        console.log('?????? 2 INSIDE OLD ADMISSION CHECK IOS: NAME', WEBVIEW_USER_AGENT_NAME_IOS);
        console.log(
          '?????? 2 INSIDE OLD ADMISSION CHECK IOS: Get User',
          window?.[WEBVIEW_USER_AGENT_NAME_IOS]?.getUser,
        );
        console.log('?????? INSIDE OLD ADMISSION FUNCTION FOUND');
        const user = getUserDetailsFromWebviewContextOrCookie(self.cookie);
        console.log('?????? INSIDE OLD ADMISSION USER DATA: ', JSON.stringify(user));
        if (user) {
          console.log('?????? INSIDE IF ADMISSION');
          if (user.role?.includes?.('lead')) {
            console.log('?????? INSIDE ADMISSION USER ROLE', user.role);
            // self.cookie && self.cookie.put('alu', JSON.stringify(user), {
            //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
            //   domain: '.sunstone.in',
            // });
            // self.cookie.remove('psu', {
            //   domain: '.sunstone.in',
            // });
            // self.cookie && self.cookie.put('alu', JSON.stringify(user), {
            //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
            // });
            // self.cookie.remove('psu');

            updateUserDetailsIntoCookie(user, self.cookie);

            console.log('?????? ADMISSION COOKIE CHECK ALU', self.cookie.get('alu'));
            console.log('?????? ADMISSION COOKIE CHECK PSU', self.cookie.get('psu'));

            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          console.log('?????? ADMISSION INSIDE ELSE');
          // open native login - > redirect to new url -> login action handling
          const queryParams: WebviewAction = {
            action: NativeActions.LOGIN,
            onActionSuccess: `${window.location.origin}${Urls.APP_LOGIN}`,
            onActionFailure: `${window.location.origin}${Urls.APP_LOGIN}`,
          };
          window.location.href = `${window.location.origin}/action?nativeAction=${JSON.stringify(queryParams)}`;
          resolve(false);
        }
      }

      console.log('?????? 3 INSIDE OLD ADMISSION CHECK ANDROID: NAME', WEBVIEW_USER_AGENT_NAME);
      console.log('?????? 3 INSIDE OLD ADMISSION CHECK ANDROID: Get User', window?.[WEBVIEW_USER_AGENT_NAME]?.getUser);
      console.log('?????? 3 INSIDE OLD ADMISSION CHECK IOS: NAME', WEBVIEW_USER_AGENT_NAME_IOS);
      console.log('?????? 3 INSIDE OLD ADMISSION CHECK IOS: Get User', window?.[WEBVIEW_USER_AGENT_NAME_IOS]?.getUser);

      console.log('?????? ADMISSION CHECKING COOKIE', this.cookie.get('alu') && JSON.stringify(this.cookie.get('alu')));

      const newUserData = getUserDetailsFromWebviewContextOrCookie(self.cookie);

      if (newUserData) {
        self.userDetails = newUserData;
        self.apiheader['Authorization'] = 'Bearer ' + this.userDetails['session_key'];
        resolve(true);
      } else {
        self.logoutAdmissionUser(false, 'guard');
        resolve(false);
      }
    });
  }

  checkAdmission() {
    let self = this;
    return new Promise(async (resolve, reject) => {
      console.log('?????? INSIDE NEW ADMISSION CHECK PROMISE: Get User', getWebviewContext()?.getUser);
      // if in webview then auto login user
      console.log(
        '?????? RESPONSE FROM getWebviewContext() - checkAdmission',
        getWebviewContext() && JSON.stringify(getWebviewContext()),
      );

      if (getWebviewContext()?.getUser) {
        console.log('?????? INSIDE NEW ADMISSION FUNCTION FOUND');
        const user = getUserDetailsFromWebviewContextOrCookie(self.cookie);
        console.log('?????? INSIDE NEW ADMISSION USER DATA: ', JSON.stringify(user));
        if (user) {
          console.log('?????? INSIDE IF ADMISSION');
          // self.cookie && self.cookie.put('alu', JSON.stringify(user), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
          //   domain: '.sunstone.in',
          // });
          // self.cookie && self.cookie.put('psu', JSON.stringify(user), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
          //   domain: '.sunstone.in',
          // });
          // self.cookie && self.cookie.put('alu', JSON.stringify(user), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90))
          // });
          // self.cookie && self.cookie.put('psu', JSON.stringify(user), {
          //   expires: new Date(new Date().setDate(new Date().getDate() + 90))
          // });

          updateUserDetailsIntoCookie(user, self.cookie);

          resolve(true);
        } else {
          console.log('?????? INSIDE ELSE ADMISSION');
          // open native login - > redirect to new url -> login action handling
          const queryParams: WebviewAction = {
            action: NativeActions.LOGIN,
            onActionSuccess: `${window.location.origin}${Urls.APP_LOGIN}`,
            onActionFailure: `${window.location.origin}${Urls.APP_LOGIN}`,
          };
          window.location.href = `${window.location.origin}/action?nativeAction=${JSON.stringify(queryParams)}`;
          resolve(false);
        }
      }

      const newUserData = getUserDetailsFromWebviewContextOrCookie(self.cookie);
      if (newUserData) {
        self.userDetails = newUserData;
        self.apiheader['Authorization'] = 'Bearer ' + self.userDetails['session_key'];
        resolve(true);
      } else {
        self.logoutAdmissionUser(false, 'guard');
        resolve(false);
      }
    });
  }

  openToast(message, action = 'Ok', duration = 10000) {
    this.zone.run(() => {
      this.snackBar.open(message, action, {
        duration,
      });
    });
  }

  loginRedirectionStudent(source, redirectFlutter = true) {
    console.log(`?????? INSIDE LOGIN REDIRECTION STUDENT - SOURCE`, source);
    console.log(
      `?????? INSIDE LOGIN REDIRECTION STUDENT - redirectFlutter`,
      redirectFlutter,
      this.cookie.get('spendingState'),
    );
    const pendingStateCookie = this.cookie.get(ADMISSION_PENDING_STATE_COOKIE);
    let urlParams = '';
    if (pendingStateCookie) {
      urlParams = `?redirect_url=${window.location.origin}${pendingStateCookie}`;
      this.cookie.remove(ADMISSION_PENDING_STATE_COOKIE, {domain: '.sunstone.in'});
    }
    if (!window.location.href.includes('localhost')) {
      window.location.href = `${environment.urls.hub}/student${urlParams}`;
    }

    // this won't work on local
    // if (redirectFlutter) {
    // } else {
    //   this.zone.run(() => {
    //     this.pendingState = this.cookie.get('spendingState');
    //     if (this.pendingState) {
    //       this.router.navigate([this.pendingState], {
    //         queryParamsHandling: 'preserve',
    //       });
    //       this.pendingState = '';
    //       this.cookie.remove('spendingState');
    //     } else {
    //       this.router.navigate(['/dashboard'], {
    //         queryParamsHandling: 'preserve',
    //       });
    //     }
    //   });
    // }
  }

  async onSignInSuccess(auth_token) {
    return new Promise(async (resolve, reject) => {
      console.log('in onSignInSuccess');
      this.postApi('login/loginStudent', {token: auth_token, redirect_uri: this.redirectUri}, {})
        .then((data) => {
          if (data['success']) {
            let user = {
              id: data['user']['id'],
              name: data['user']['name'],
              session_key: data['user']['session_key'],
              email: data['user']['email'],
              profileImage: data['user']['profileImage'],
              role: data['user']['role'],
            };

            // this.cookie && this.cookie.put('psu', JSON.stringify(user), {
            //   expires: new Date(new Date().setDate(new Date().getDate() + 90)),
            //   domain: '.sunstone.in',
            // });

            updateUserDetailsIntoCookie(user, this.cookie);

            this.userLoggedin = true;
            this.loginRedirectionStudent('onSignInSuccess');
            resolve(true);
          } else {
            this.logoutUser();
            this.openToast(data['message'] || 'Something went wrong. Please try again.', 'Ok');
            reject(data['message'] || 'Something went wrong. Please try again.');
          }
        })
        .catch((err) => {
          this.openToast((err && err.error && err.error['message']) || 'Something went wrong. Please try again.', 'Ok');
          this.logoutUser();
          console.log(err);
          reject(err);
        });
    });
  }

  onSignInFailed(error) {
    this.openToast(error.error, 'OK');
  }

  logoutUser(reload?) {
    // After the user logs out , ensure to call the logout method of MoEngage.
    Moengage()?.destroy_session();
    this.fireSocialEvent('HUB_LOGOUT', '', '', [TRACKING_PLATFORMS.GA, TRACKING_PLATFORMS.MO_WE]);
    this.userLoggedin = false;
    this.userDetails = {};
    this.studentData = {};
    this.router.navigate(['/']);
    // log out from the app
    this.logoutFromApp();
    sessionStorage.clear();
    localStorage.clear();

    deleteUserCookie(this.cookie);

    if (reload) {
      setTimeout(() => {
        window.location.reload();
      }, 0);
    }
  }

  logoutAdmissionUser(reload?, source?) {
    console.log('?????? INSIDE logoutAdmissionUser - source', source);
    // After the user logs out , ensure to call the logout method of MoEngage.
    Moengage()?.destroy_session();
    this.showLoader = true;
    this.studentData = {};
    this.store.dispatch(clearAdmissionStore());

    deleteUserCookie(this.cookie);
    if (reload) {
      this.fireSocialEvent('Log_Out', '', '', ['ga', 'we'], {
        pagename: this.currentpage,
      });
      setTimeout(() => {
        window.location.reload();
      }, 0);
    }
    if (!source) {
      console.log('?????? INSIDE logoutAdmissionUser - calling logoutfromapp');
      this.logoutFromApp();
    }
    sessionStorage.clear();
    localStorage.clear();
    this.showLoader = false;
  }

  fetchUnreadCount() {
    console.log('header in fetchUnreadCount ', this.apiheader);
    this.getApi('student/fetchUnreadCount', this.apiheader)
      .then((data) => {
        if (data['success']) {
          this.unreadCount = data['count'];
        } else {
          this.openToast(data['message'] || 'Something went wrong. Please try again.', 'Ok');
        }
      })
      .catch((err) => {
        this.openToast((err && err.error && err.error['message']) || 'Something went wrong. Please try again.', 'Ok');
      });
  }

  findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
      if (predicate(array[l], l, array)) return l;
    }
    return -1;
  }

  fetchStudentData() {
    return new Promise(async (resolve, reject) => {
      // console.log("inside fetch student data");
      this.showLoader = true;
      this.getApi('student/fetchStudentProfile?id=' + this.userDetails['id'], this.apiheader)
        .then((data) => {
          if (data['success']) {
            this.studentData = data['data'];
            this.isAcceptedBefore = this.studentData.is_accepted_before;
            // logout if found not student
            // if (this.studentData.role != 'student' && (!window || !window['flutter_inappwebview'])) {
            //   this.openToast('Session expired. Please login again', 'Ok');
            //   this.logoutAdmissionUser();
            //   this.logoutUser();
            //   this.router.navigate(['/'], {queryParamsHandling: 'preserve'});
            //   return;
            // }
            // this.studentData.sunstone_agreement = '0';
            if (!this.studentData.userEditRequestsData) {
              this.studentData.userEditRequestsData = {};
            }

            // is online mba student
            if (this.programService.onlinePgPrograms.includes(this.studentData.program)) {
              this.isOnlineStudent = true;
              // this.router.navigate(['/profile'], {queryParamsHandling: 'preserve'});
            }

            if (this.studentData?.admission_year) {
              let endyear = '' + (parseInt(this.studentData.admission_year) + 2);
              endyear = endyear.slice(-2);
              this.studentData.batch = this.studentData.admission_year + '-' + endyear;
            }

            // find CV
            if (this.studentData['student_documents'] && this.studentData['student_documents'].length) {
              let currCvIndex = this.findLastIndex(this.studentData['student_documents'], (x: any) => {
                return this.studentData.role.indexOf('online_student') > -1
                  ? x.document_name == 'cv'
                  : x.document_name == 'mentor_cv' && x.status == '1';
              });
              if (currCvIndex > -1) {
                this.cvData = {
                  url: this.studentData.mentor_cv_url,
                  name: this.studentData.student_documents[currCvIndex].document_value,
                };
              }
            }

            // debard data
            if (this.studentData['debard_data'] && this.studentData['debard_data'].no_of_opportunities) {
              this.studentData['debard_data'].punishment_opportunities =
                (this.studentData['debard_data'].punishment_opportunities &&
                  this.studentData['debard_data'].punishment_opportunities.split(',')) ||
                [];
            }

            if (this.studentData.state_data) {
              this.studentData.state_name = this.studentData.state_data.name;
            }

            if (this.studentData.city_data) {
              this.studentData.city_name = this.studentData.city_data.name;
            }

            // make blank student data if not exists
            if (!this.studentData['student_data']) {
              this.studentData['student_data'] = {};
            }

            // calculate gpa max
            this.studentData['student_data']['x_gpa_max'] = this.studentData['student_data']['x_gpa_max']
              ? this.studentData['student_data']['x_gpa_max']
              : 10;
            this.studentData['student_data']['xii_gpa_max'] = this.studentData['student_data']['xii_gpa_max']
              ? this.studentData['student_data']['xii_gpa_max']
              : 10;
            this.studentData['student_data']['graduation_gpa_max'] = this.studentData['student_data'][
              'graduation_gpa_max'
            ]
              ? this.studentData['student_data']['graduation_gpa_max']
              : 10;

            // fetch campus and json data
            if (this.studentData?.campus_data?.campus_name) {
              let campusname = this.studentData.campus_data.campus_name;
              this.campusImages = this.dashboardData.campusData[campusname]?.lifeImages;
              this.near_pg_data = this.dashboardData.campusData[campusname]?.near_pg;
              this.nearest_railway = this.dashboardData.campusData[campusname]?.nearest_railway;
              this.nearest_airport = this.dashboardData.campusData[campusname]?.nearest_airport;
              this.faqs = this.dashboardData?.faqs;
              this.meet_seniors = this.dashboardData?.meet_seniors;
              this.handbookUrl = this.dashboardData.campusData[campusname]?.handbook;
            }

            // fresh desk initilisation.
            // if (this.studentData.role == 'student') {
            //   if (this.isBrowser()) {
            //     if (!window['sessionStorage'].tktId && !window['sessionStorage'].cstId) {
            //       window['sessionStorage'].tktId = new Date().getTime();
            //       window['sessionStorage'].cstId = this.studentData.id;
            //       // {tktId: , cstId: this.studentData.id} ;
            //     }
            //     let freshDesk = document.createElement('script');
            //     freshDesk.innerHTML = `(function (d, w, c) { if(!d.getElementById("spd-busns-spt")) { var n = d.getElementsByTagName('script')[0], s = d.createElement('script'); var loaded = false; s.id = "spd-busns-spt"; s.async = "async"; s.setAttribute("data-self-init", "false"); s.setAttribute("data-init-type", "opt"); s.src = 'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js'; s.setAttribute("data-client", "33c5ca4d8b97cef8fe5231ea6aadcd7fd5c631fd"); s.setAttribute("data-bot-hash", "98b96f308158873f455cf5e1d0610a0337139689"); s.setAttribute("data-env", "prod"); s.setAttribute("data-region", "in"); if (c) { s.onreadystatechange = s.onload = function () { if (!loaded) { c(); } loaded = true; }; } n.parentNode.insertBefore(s, n); } }) (document, window, function () { Freshbots.initiateWidget({ autoInitChat: false, getClientParams: function () { return {"sn::cstmr::id":"${window['sessionStorage'].cstId}","cstmr::eml":"${this.studentData.sunstone_email}","xtrnlTcktId":"${window['sessionStorage'].tktId}","cstmr::phn":"${this.studentData.mobile}","cstmr::nm":"${this.studentData.name}"}; } }, function(successResponse) { }, function(errorResponse) { }); });`;
            //     document.head.appendChild(freshDesk);
            //   }
            // }

            // agreement data check
            if (this.studentData.fee_structure_type) {
              switch (this.studentData.fee_structure_type) {
                // case 'PAP-65K':
                //   this.slide_image = this.pap_withscoller_k;
                //   break;
                case 'PAP-125K':
                  this.slide_image = this.pap_withoutscoller_k;
                  break;
                case 'MBG':
                  this.slide_image = this.mbg_withscoller_k;
                  // mbg_withoutcoller_k
                  break;
                // case 'MBG-255K':
                //   this.slide_image = this.mbg_withscoller_k;
                //   break;
                default:
                  this.studentData.sunstone_agreement = '1';
                  break;
              }
            } else {
              // this.studentData.sunstone_agreement = '1';
            }

            // load ga if not present
            if (!window['gtag']) {
              let gtagScript = document.createElement('script');
              gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=UA-131771429-10`;
              document.head.appendChild(gtagScript);

              let gtagCode = document.createElement('script');
              gtagCode.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-131771429-10');`;
              document.head.appendChild(gtagCode);
            }

            console.log('studentData ', this.studentData);

            resolve(data);
          } else {
            this.openToast(data['message'] || 'Error fetching student data. Please try again later.', 'Ok');
            reject(false);
          }
          this.showLoader = false;
        })
        .catch((err) => {
          console.log('Error fetching student data', err);
          this.openToast(
            (err && err.error && err.error['message']) || 'Error fetching student data. Please try again later.',
            'Ok',
          );
          this.showLoader = false;
          reject(false);
        });
    });
  }

  openAgreementPopup(index?) {
    this.sliderPopup = true;
  }

  closeAgreementPopup() {
    this.sliderPopup = false;
  }

  isMobile() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.window.innerWidth < 991) {
        return true;
      } else {
        return false;
      }
    }
  }

  fetchProgramList(listType) {
    return new Promise((resolve, reject) => {
      this.showLoader = true;
      // hub - for hub response
      // console - for console response
      // online - for only online programs
      // default all will return except online
      this.getApi('api/fetchAllProgramList?origin=' + listType, this.apiheader)
        .then((resp: any) => {
          if (resp.success) {
            if (listType == 'hub') {
              this.programList = resp['data'];
              this.programList.forEach((element) => {
                this.programDict['' + element.id] = element.name;
              });
            } else if (listType == 'online') {
              this.specialisation_list = resp['data'];
              this.specialisation_list.forEach((element) => {
                this.onlineCampus.push(element.id);
                this.onlineCampusDict['' + element.id] = element.name;
              });
            } else if (listType === 'all') {
              this.programList = resp['data'];
              this.programList.forEach((element) => {
                this.programDict['' + element.id] = element.name;
              });
              if (this.studentData) {
                this.specialisation_list = resp['data'];
                this.specialisation_list.forEach((element) => {
                  this.onlineCampus.push(element.id);
                  this.onlineCampusDict['' + element.id] = element.name;
                });
              }
            }
          } else {
            this.openToast(resp['message'] || 'Error in fetching program list', 'Ok');
          }
          this.showLoader = false;
          resolve(true);
        })
        .catch((err) => {
          console.log('Error fetching program list', err);
          this.openToast((err && err.error && err.error['message']) || 'Something went wrong. Please try again.', 'Ok');
          reject(err);
          this.showLoader = false;
        });
    });
  }

  calcMarks() {
    // BBA
    if (
      Number(this.studentData.student_data.xii_year) &&
      Number(this.studentData.student_data.xii_year) < this.eligibleYear
    ) {
      this.studentData.xii_score_eligible = true;
    }
    if (this.studentData.student_data.xii_percentage) {
      this.studentData.xii_score_type = '1';
    } else if (this.studentData.student_data.xii_gpa) {
      this.studentData.xii_score_type = '2';
    } else {
      this.studentData.xii_score_type = '1';
      if (this.studentData.student_data.xii_percentage) {
        this.studentData.xii_score_type = '1';
      } else if (this.studentData.student_data.xii_gpa) {
        this.studentData.xii_score_type = '2';
      }
    }

    // MBA
    if (
      Number(this.studentData.student_data.graduation_year) &&
      Number(this.studentData.student_data.graduation_year) < this.eligibleYear
    ) {
      this.studentData.grad_score_eligible = true;
    }

    if (this.studentData.student_data.graduation_percentage) {
      this.studentData.student_data.graduation_score_type = '1';
    } else if (this.studentData.student_data.graduation_gpa) {
      this.studentData.student_data.graduation_score_type = '2';
    } else {
      this.studentData.student_data.graduation_score_type = '1';
      if (this.studentData.student_data.graduation_percentage) {
        this.studentData.student_data.graduation_score_type = '1';
      } else if (this.studentData.student_data.gradutaion_gpa) {
        this.studentData.student_data.graduation_score_type = '2';
      }
    }

    if (
      this.studentData.student_data.graduation_percentage &&
      Number(this.studentData.student_data.graduation_percentage) >= 45 &&
      Number(this.studentData.student_data.graduation_percentage) <= 50
    ) {
      this.studentData.category = true;
    }
    if (this.studentData.student_data.gradutaion_gpa) {
      let percentCalc =
        (Number(this.studentData.student_data.gradutaion_gpa) /
          Number(this.studentData.student_data.graduation_gpa_max)) *
        100;
      if (percentCalc >= 45 && percentCalc <= 50) {
        this.studentData.category = true;
      } else {
        this.studentData.category = false;
      }
    }
  }

  loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.src = url;
    script.onload = () => {
      this._loadedLibraries[url].next(null);
      this._loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }

  loadStyle(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const style = this.document.createElement('link');
    style.type = 'text/css';
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      this._loadedLibraries[url].next(null);
      this._loadedLibraries[url].complete();
    };

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    return this._loadedLibraries[url].asObservable();
  }

  getApi(url, header = this.apiheader, params?): Promise<IApiResponse> {
    return new Promise(async (resolve, reject) => {
      if (!this.isBrowser() || navigator) {
        return this.http.get(this.hostUrl + url, {headers: header, params}).subscribe(
          (data: IApiResponse) => {
            resolve(data);
          },
          (err) => {
            if (
              err.status == 403 &&
              !window.location.pathname.includes('freshdesk') &&
              !window.location.pathname.includes('refund-desk') &&
              !window.location.pathname.includes('content-feedback')
            ) {
              this.openToast(
                (err && err.error && err.error['message']) || 'Something went wrong. Please try again.',
                'Ok',
              );
              this.logoutUser();
            } else {
              reject(err);
            }
          },
        );
      } else {
        this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
        this.showLoader = false;
      }
    });
  }

  deleteApi(url, header = this.apiheader, params?): Promise<IApiResponse> {
    return new Promise(async (resolve, reject) => {
      if (!this.isBrowser() || navigator) {
        return this.http.delete(this.hostUrl + url, {headers: header, params}).subscribe(
          (data: IApiResponse) => {
            resolve(data);
          },
          (err) => {
            if (
              err.status == 403 &&
              !window.location.pathname.includes('freshdesk') &&
              !window.location.pathname.includes('refund-desk') &&
              !window.location.pathname.includes('content-feedback')
            ) {
              this.openToast(
                (err && err.error && err.error['message']) || 'Something went wrong. Please try again.',
                'Ok',
              );
              this.logoutUser();
            } else {
              reject(err);
            }
          },
        );
      } else {
        this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
        this.showLoader = false;
      }
    });
  }

  get(url, header = this.apiheader): Observable<object> {
    if (!this.isBrowser() || navigator) {
      return this.http.get(this.hostUrl + url, {headers: header});
    } else {
      this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
      this.showLoader = false;
    }
  }

  post(url, body, header = this.apiheader): Observable<object> {
    if (!this.isBrowser() || navigator) {
      return this.http.post(this.hostUrl + url, body, {headers: header});
    } else {
      this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
      this.showLoader = false;
    }
  }

  put(url, body, header = this.apiheader): Observable<object> {
    if (!this.isBrowser() || navigator) {
      return this.http.put(this.hostUrl + url, body, {headers: header});
    } else {
      this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
      this.showLoader = false;
    }
  }

  delete(url, header = this.apiheader): Observable<object> {
    if (!this.isBrowser() || navigator) {
      return this.http.delete(this.hostUrl + url, {headers: header});
    } else {
      this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
      this.showLoader = false;
    }
  }

  postApi(url, body, header: any = this.apiheader): Promise<IApiResponse> {
    return new Promise(async (resolve, reject) => {
      if (!this.isBrowser() || navigator) {
        return this.http.post(this.hostUrl + url, body, {headers: header}).subscribe(
          (data: IApiResponse) => {
            resolve(data);
          },
          (err) => {
            if (
              err.status == 403 &&
              !window.location.pathname.includes('freshdesk') &&
              !window.location.pathname.includes('refund-desk') &&
              !window.location.pathname.includes('content-feedback')
            ) {
              this.openToast(
                (err && err.error && err.error['message']) || 'Something went wrong. Please try again.',
                'Ok',
              );
              this.logoutUser();
            } else {
              reject(err);
            }
          },
        );
      } else {
        this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
        this.showLoader = false;
      }
    });
  }

  putApi(url, body, header: any = this.apiheader): Promise<IApiResponse> {
    return new Promise(async (resolve, reject) => {
      if (!this.isBrowser() || navigator) {
        return this.http.put(this.hostUrl + url, body, {headers: header}).subscribe(
          (data: IApiResponse) => {
            resolve(data);
          },
          (err) => {
            if (
              err.status == 403 &&
              !window.location.pathname.includes('freshdesk') &&
              !window.location.pathname.includes('refund-desk') &&
              !window.location.pathname.includes('content-feedback')
            ) {
              this.openToast(
                (err && err.error && err.error['message']) || 'Something went wrong. Please try again.',
                'Ok',
              );
              this.logoutUser();
            } else {
              reject(err);
            }
          },
        );
      } else {
        this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
        this.showLoader = false;
      }
    });
  }

  putApiFull(url, body, header = this.apiheader, extraheader?) {
    return new Promise(async (resolve, reject) => {
      let apihead = {};
      if (header) {
        apihead['Authorization'] = this.apiheader['Authorization'];
      }
      if (extraheader != undefined) {
        apihead['Content-Type'] = extraheader['Content-Type'];
      }
      return this.http.put(url, body, {headers: apihead, observe: 'response'}).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          if (err.status == 403) {
            this.openToast('Session Expired. Please login again.', 'Ok');
            this.logoutUser();
          } else {
            reject(err);
          }
        },
      );
    });
  }

  admitgetApi(url, header = this.apiheader): Promise<IApiResponse> {
    return new Promise(async (resolve, reject) => {
      if (!this.isBrowser() || navigator) {
        return this.http.get(this.admissionHost + url, {headers: header}).subscribe(
          (data: IApiResponse) => {
            resolve(data);
          },
          (err) => {
            if (err.status == 403) {
              this.openToast(
                (err && err.error && err.error['message']) || 'Something went wrong. Please try again.',
                'Ok',
              );
              this.logoutAdmissionUser();
            } else {
              reject(err);
            }
          },
        );
      } else {
        this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
        this.showLoader = false;
      }
    });
  }

  admitGet(url: string, header = this.apiheader): Observable<object> {
    if (!this.isBrowser() || navigator) {
      return this.http.get(this.hostUrl + url, {headers: header});
    } else {
      this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
      this.showLoader = false;
    }
  }

  admitDelete(url, header = this.apiheader): Observable<object> {
    if (!this.isBrowser() || navigator) {
      return this.http.delete(this.admissionHost + url, {headers: header});
    } else {
      this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
      this.showLoader = false;
    }
  }

  admitpostApi(url, body, header: any = this.apiheader): Promise<IApiResponse> {
    return new Promise(async (resolve, reject) => {
      if (!this.isBrowser() || navigator) {
        return this.http.post(this.admissionHost + url, body, {headers: header}).subscribe(
          (data: IApiResponse) => {
            resolve(data);
          },
          (err) => {
            if (err.status == 403) {
              this.openToast(
                (err && err.error && err.error['message']) || 'Something went wrong. Please try again.',
                'Ok',
              );
              this.logoutAdmissionUser();
            } else {
              reject(err);
            }
          },
        );
      } else {
        this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
        this.showLoader = false;
      }
    });
  }

  admitPost(url, body, header: any = this.apiheader): Observable<any> {
    if (!this.isBrowser() || navigator) {
      return this.http.post(this.admissionHost + url, body, {
        headers: header,
      });
    } else {
      this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
      this.showLoader = false;
    }
  }

  miscgetApi(url, header) {
    return new Promise(async (resolve, reject) => {
      if (!this.isBrowser() || navigator) {
        return this.http.get(this.miscellaneousUrl + url, {headers: header}).subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            if (err.status == 403) {
              this.openToast(
                (err && err.error && err.error['message']) || 'Something went wrong. Please try again.',
                'Ok',
              );
              this.logoutAdmissionUser();
            } else {
              reject(err);
            }
          },
        );
      } else {
        this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
        this.showLoader = false;
      }
    });
  }

  miscpostApi(url, body, header) {
    return new Promise(async (resolve, reject) => {
      if (!this.isBrowser() || navigator) {
        return this.http.post(this.miscellaneousUrl + url, body, {headers: header}).subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            if (err.status == 403) {
              this.openToast(
                (err && err.error && err.error['message']) || 'Something went wrong. Please try again.',
                'Ok',
              );
              this.logoutAdmissionUser();
            } else {
              reject(err);
            }
          },
        );
      } else {
        this.openToast('Oops! You are offline. Please check your internet connection.', 'Ok');
        this.showLoader = false;
      }
    });
  }

  isFlutterWebView(): boolean {
    // return !!window['flutter_inappwebview'];
    return !!window['Android'];
  }

  setStudentData(userData: any): void {
    this.studentData = {...userData};
    // Just in case
    if (this.isBrowser()) {
      setTimeout(() => {
        console.log('Attemting login to MoEngage');
        Moengage()?.add_unique_user_id(userData.id);
      }, 5000);
    }

    // set eligible year
    if (this.studentData.eligibility_check_date) {
      this.eligibleYear = parseInt(this.studentData.eligibility_check_date);
    } else {
      this.eligibleYear = new Date().getFullYear() + 1;
    }

    // logout if found student
    if (this.studentData.role == 'student' && (!window || !window['flutter_inappwebview'])) {
      this.openToast('Session expired. Please login again', 'Ok');
      this.logoutAdmissionUser();
      this.logoutUser();
      this.router.navigate(['/'], {queryParamsHandling: 'preserve'});
      return;
    }

    if (!this.studentData.student_data) {
      this.studentData.student_data = {};
    }
    if (this.studentData.program) {
      if (!this.programDict[this.studentData.program]) {
        if (this.onlineCampusDict[this.studentData.program]) {
          this.studentData.online_campus = this.studentData.program;
          this.isOnlineStudent = true;
        } else {
        }
      }
    } else {
      this.studentData.profile_completion_status = '1';
    }

    switch (this.studentData.profile_completion_status) {
      case ProfileCompletion.DOCUMENT_VERIFIED: {
        if (this.isOnlineStudent && this.studentData.start_onboarding) {
          this.stepform = 10;
        }
      }
      case '11': //Seat Booked
        if (this.studentData.start_onboarding) {
          this.stepform = 10;
          this.studentData.seat_book_success = true;
        } else {
          // this.stepform = 7;
          this.stepform = 14;
        }
        this.calcMarks();
        break;
      case '15': //Full Amount Paid
        if (this.studentData.start_onboarding) {
          this.stepform = 10;
          this.studentData.seat_book_success = true;
        } else {
          // this.stepform = 7;
          this.stepform = 14;
        }
        this.calcMarks();
        break;
      case '16': //Offer Letter Released
        this.stepform = 14;

        this.calcMarks();
        break;
      default:
        this.stepform = 7;
        this.calcMarks();
        break;
    }

    // find last digit of userId
    this.studentData.user_id_last = this.studentData.id.toString().slice(-1);

    // setup others
    if (isPlatformBrowser(this.platformId)) {
      if (this.studentData.how_came_to_know_sub) {
        let main_option = this.studentData.how_came_to_know;
        if (this.how_come_know[main_option].indexOf(this.studentData.how_came_to_know_sub) == -1) {
          this.studentData.how_came_to_know_sub_other = this.studentData.how_came_to_know_sub;
          if (main_option == 'Recommended by someone') {
            this.studentData.how_came_to_know_sub = 'Someone Else';
          } else {
            this.studentData.how_came_to_know_sub = 'Others';
          }
        }
      }
    }

    // load ga if not present
    if (!window['gtag']) {
      let gtagScript = document.createElement('script');
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=UA-131771429-8`;
      document.head.appendChild(gtagScript);

      let gtagCode = document.createElement('script');
      gtagCode.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-131771429-8');`;
      document.head.appendChild(gtagCode);
    }

    // next due payment
    if (this.studentData.next_due_payment && Object.keys(this.studentData.next_due_payment).length) {
      this.studentData.next_due_payment.due_amount =
        this.studentData.next_due_payment.total_amount - this.studentData.next_due_payment.amount_paid;
    }

    if (!this.pixelLoaded) {
      let pixelScript = document.createElement('img');
      pixelScript.src = `https://proformics.vnative.net/pixel?adid=617692fd032a244a7b6bd545&sub1=${this.studentData.id}`;
      document.body.appendChild(pixelScript);
      this.pixelLoaded = true;
    }
  }

  private logoutFromApp() {
    console.log(
      '?????? RESPONSE FROM getWebviewContext() - logoutFromApp',
      getWebviewContext() && JSON.stringify(getWebviewContext()),
    );
    if (Boolean(getWebviewContext())) {
      getWebviewContext()?.logOut?.();
      getWebviewContext()?.callHandler?.('logOut', null);
      const queryParams: WebviewAction = {
        action: NativeActions.LOGOUT,
        onActionSuccess: '',
        onActionFailure: '',
      };
      window.location.href = `${window.location.origin}/action?nativeAction=${JSON.stringify(queryParams)}`;
    }
  }
}

import {StageNames} from './stage-resolver.enums';

export const MODULE_ROUTE_MAP: Record<StageNames, string> = {
  [StageNames.FRESH_LEAD]: 'application',
  [StageNames.APPLICATION_FORM]: 'application',
  [StageNames.TEST]: 'schedule-test',
  [StageNames.INTERVIEW]: 'interview',
  [StageNames.DOCUMENTATION_PG]: 'documentation',
  [StageNames.DOCUMENTATION_UG]: 'documentation',
  [StageNames.SELECTION]: 'documentation',
  [StageNames.OFFER_LETTER]: 'documentation',
  [StageNames.SEAT_BOOKING]: 'seat-booking',
  [StageNames.REFUND]: 'seat-booking',
};

// this is the default development env
export const environment = {
  production: false,
  envName: 'development',
  urls: {
    // host: 'https://test-admission.sunstone.in/placement_apis/',
    // admissionHost: 'https://test-admission.sunstone.in/apis/',
    // host: 'http://localhost:3004/',
    host: 'https://dev-api.sunstone.in/hub/',
    admissionHost: 'https://dev-api.sunstone.in/student/',
    // admissionHost: 'http://192.168.2.141:3000/',
    misc: 'https://dev-miscellanous.sunstone.in/',
    s3: 'https://d1nu44pjiysgk4.cloudfront.net/',
    socket: 'wss://dev-hub-console-api.sunstone.in:3004',
    quizZone: 'https://dev.sunstone.in/passion-ki-khoj/passion-ki-khoj/home',
    consoleUrl: 'https://dev-console.sunstone.in/',
    website: 'https://dev-web.sunstone.in/',
    // website: 'http://localhost:4600/',
    cdn: 'https://d2y595cx06dch3.cloudfront.net/',
    hub: 'https://dev-hub.sunstone.in',
  },
  firebase: {
    apiKey: 'AIzaSyDBxtM1ZGnp2dYVaPvmqZWv2w1mwOVJ3IY',
    authDomain: 'studenthub-dev.firebaseapp.com',
    databaseURL: 'https://studenthub-dev-default-rtdb.firebaseio.com',
    projectId: 'studenthub-dev',
    storageBucket: 'studenthub-dev.appspot.com',
    messagingSenderId: '718442225380',
    appId: '1:718442225380:web:fe75c92a69f9807bce16f1',
    measurementId: 'G-PWKCEVG6Z1',
  },
  googleAuth: {
    clientId: '197395073868-21na04vu8fk0gdvftp4oteaar05mk81r.apps.googleusercontent.com',
  },
  facebookAuth: {
    appId: '880278985977242',
    version: 'v2.8',
  },
  linkedinAuth: {
    clientId: '78ixd5280iwtvm',
    scope: 'r_liteprofile%20r_emailaddress',
  },
  mixpanelToken: 'cb8adac1c9f4e180c82da3f813624d72',
  cashfreeScript: 'https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js',
};

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {MODULE_ROUTE_MAP} from 'src/app/admission-new/modules/lead-journey/stage-resolver.constants';
import {getCurrentStage} from 'src/app/admission-new/modules/lead-journey/stage-resolver.utils';
import {AppService} from 'src/app/services/app.service';
import {StageResolverService} from 'src/app/admission-new/modules/lead-journey/stage-resolver.service';
import {lastValueFrom} from 'rxjs';
import {EligibilityService} from '../../admission-new/shared/services/eligibility.service';
import {TaskNames} from '../../admission-new/modules/lead-journey/stage-resolver.enums';
import {FlutterRedirectionService} from '../../admission-new/shared/services/flutter-redirection.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private appService: AppService,
    private stageResolverService: StageResolverService,
    private router: Router,
    private eligibilityService: EligibilityService,
    private flutterRedirectionService: FlutterRedirectionService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let checkAuth: any;
    console.log(`?????? INSIDE LOGIN GUARD`);
    const isFlutterActive = this.flutterRedirectionService.checkIfFlutterUser();
    console.log(`?????? INSIDE LOGIN GUARD - isFlutterActive`, isFlutterActive);
    if (isFlutterActive) {
      console.log(`?????? INSIDE LOGIN GUARD - ACTIVATING NEW LOGIN FLOW`);
      checkAuth = await this.appService.checkLogin();
    } else {
      console.log(`?????? INSIDE LOGIN GUARD - ACTIVATING OLD LOGIN FLOW`);
      checkAuth = await this.appService.checkLoginOld();
    }
    if (!checkAuth) {
      console.log(`?????? INSIDE LOGIN GUARD - AUTH FOUND NOT GOING TO LOGIN`);
      try {
        const [eligibility] = await Promise.all([
          lastValueFrom(this.eligibilityService.fetchEligibility()),
          this.stageResolverService.fetchLeadJourney(),
        ]);
        if (!eligibility.eligible) {
          console.log(`?????? INSIDE LOGIN GUARD - NOT ELIGIBLE`);
          return this.router.createUrlTree(['admission', 'not-eligible'], {
            queryParamsHandling: 'preserve',
          });
        }
        console.log(`?????? INSIDE LOGIN GUARD - ELIGIBILITY - ${eligibility.eligible}`);
        const isRefundStage =
          this.stageResolverService.isComplete(TaskNames.APPROVE_REFUND) ||
          this.stageResolverService.isComplete(TaskNames.REQUEST_REFUND);
        if (isRefundStage) {
          console.log(`?????? INSIDE LOGIN GUARD - REFUNDED`);
          return this.router.createUrlTree(['admission', 'refund'], {
            queryParamsHandling: 'preserve',
          });
        }

        const journeyStep = this.stageResolverService.leadJourney;
        const newRoute: any = getCurrentStage(journeyStep);
        console.log(`?????? INSIDE LOGIN GUARD - NEW ROUTE`, newRoute);
        return this.router.createUrlTree(['admission', MODULE_ROUTE_MAP[newRoute]], {
          queryParamsHandling: 'preserve',
        });
      } catch (e) {
        console.log(`?????? INSIDE LOGIN GUARD - ERROR IN GUARD`, e);
        return true;
      }
    } else {
      console.log(`?????? INSIDE LOGIN GUARD - AUTH NOT FOUND`);
      return true;
    }
  }
}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromMyApplications from './admission.reducer';

export const selectMyApplicationState = createFeatureSelector<fromMyApplications.AdmissionState>(
  fromMyApplications.admissionFeatureKey,
);

export const selectStudentData = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.studentData,
);

export const selectTestStep = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.testStep,
);

export const profileDrawerSelector = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.profileDrawerOpen,
);

export const selectCities = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.cities,
);

export const selectStates = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.states,
);

export const selectMasterProfileStep = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.masterProfileStep,
);

export const selectProfileData = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.profileData,
);

export const selectProfileDataLoading = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.profileDataLoading,
);

export const selectWorkExpEditId = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.workExpEditId,
);

export const selectDocFormEditId = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.docFormEditId,
);

export const selectProfileCompletionPercentage = createSelector(
  selectMyApplicationState,
  (state: fromMyApplications.AdmissionState) => state.profileCompletionPercentage,
);

import {
  // ActionReducer,
  ActionReducerMap,
  // createFeatureSelector,
  // createSelector,
  MetaReducer
} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {admissionReducer} from '../admission-new/shared/admission.reducer';

export interface State {
  admission: object;
}

export const reducers: ActionReducerMap<State> = {
  admission: admissionReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

import {isPlatformBrowser} from '@angular/common';
import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {CookieService} from 'ngx-cookie';
import {
  ANALYTICS_EVENT_NAMES,
  initializeAnalytics,
  loginToMixpanel,
  pushUserDetailsToDataLayer,
  trackMixpanelEvent,
} from 'src/analytics';
import {environment} from '../environments/environment';
import {AdmissionState} from './admission-new/shared/admission.reducer';
import {selectStudentData} from './admission-new/shared/admission.selectors';
import {GA4_SCRIPT} from './app.constants';
import {getWebviewContext, getUserDetailsFromWebviewContextOrCookie} from './services/auth.helpers';
import {StateCityService} from './services/state-city.service';
import {UtilService} from './services/util.service';
import {PlatformType, elegantScrollbarStyle} from './shared/shared.constants';
import {IUserData} from './admission-new/shared/admission.constants';

// never call appservice here - Gaurav

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  title = 'placementstudentpanel';
  userStoreData: IUserData;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private utilService: UtilService,
    public route: ActivatedRoute,
    public router: Router,
    public cookie: CookieService,
    private store: Store<AdmissionState>,
    private stateCityService: StateCityService,
  ) {
    this.store.pipe(select(selectStudentData)).subscribe((userDetails) => {
      this.userStoreData = {...userDetails};
      loginToMixpanel({
        ...userDetails,
        program: userDetails.program_data?.name,
        state: this.stateCityService.getStateListSnapshot().filter((state) => state.id === userDetails.state)?.[0]
          ?.name,
      });
      pushUserDetailsToDataLayer(userDetails);
    });
  }

  @HostListener('window:load', [])
  onLoad() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if (environment.production) {
          const gaTag = document.createElement('script');
          gaTag.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NB6JKZV');`;
          document.head.appendChild(gaTag);

          const gaNoScript = document.createElement('noscript');
          gaNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NB6JKZV"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
          document.body.appendChild(gaNoScript);
        }
        try {
          if (window.navigator && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
              for (let registration of registrations) {
                registration.unregister();
              }
            });
          }
        } catch (err) {
          console.log('Error removing service workers', err);
        }
      }, 5000);
    }

    const moEngageScript = document.createElement('script');
    moEngageScript.setAttribute('type', 'text/javascript');
    moEngageScript.innerHTML = `(function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],h={onsite:["getData","registerCallback"]};for(k in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage')
      Moengage = moe({
        app_id:'N7HJ276HE4JNVQ8UBM1AR4JM',
        debug_logs: ${environment.envName === 'development' ? 1 : 0}, // set debug_logs to 0 in production
        cluster: "DC_3"
      });`;
    document.head.appendChild(moEngageScript);

    const getSiteControlScript = document.createElement('script');
    getSiteControlScript.setAttribute('type', 'text/javascript');
    getSiteControlScript.src = 'https://l.getsitecontrol.com/d7o2lxvw.js';
    if (!Boolean(getWebviewContext())) {
      document.head.appendChild(getSiteControlScript);
    }
    (window as any).gsc =
      (window as any)?.gsc ||
      function () {
        //@ts-ignore
        (gsc.q = gsc.q || [])?.push(arguments);
      };
    setTimeout(() => {
      //passing logged in user params to getsitecontrol.
      // let userCookieValue = this.cookie.get('alu') || this.cookie.get('psu');
      // const userDetail = JSON.parse(userCookieValue);

      const userDetail = getUserDetailsFromWebviewContextOrCookie(this.cookie)

      if (userDetail) {
        let isAppInstalled = 'no';
        if (this.userStoreData?.version_code >= 100100) {
          isAppInstalled = 'yes';
        }
        (window as any)?.gsc?.('params', {
          mobile: userDetail?.mobile || null,
          user_id: userDetail.id,
          app_user: isAppInstalled,
        });
      }
    }, 2000);

    // if (environment.envName === 'prod' && !this.cookie.get('psu')) {
    //   this.embedGa4Script();
    // }
    if (
      environment.envName === 'prod' &&
      !getUserDetailsFromWebviewContextOrCookie(this.cookie)
    ) {
      this.embedGa4Script();
    }
  }

  ngOnInit(): void {
    // Dynamically check platform and add custom scrollbar style
    const userPlatform = this.utilService.userPlatform;
    if (userPlatform === PlatformType.windows) {
      this.utilService.injectStyle(elegantScrollbarStyle);
    }
    initializeAnalytics(this.router);
    //log in via whatsapp mixpanel event.
    const isLoggedInViaWhatsappKey = new URLSearchParams(window.location.search).get('vw');
    if (isLoggedInViaWhatsappKey === '1') trackMixpanelEvent(ANALYTICS_EVENT_NAMES.LOGGED_IN_VIA_WHATSAPP);
  }

  private embedGa4Script(): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('type', 'text/javascript');
    gaScript.setAttribute('id', 'ga4script');
    gaScript.innerHTML = GA4_SCRIPT;
    document.head.appendChild(gaScript);
  }
}

export enum WorkFromHome {
  NO = '0',
  YES = '1',
  HYBRID = '2',
}

export enum ModeOfInterview {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

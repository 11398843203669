import {createAction} from '@ngrx/store';
import {
  AdmissionFormStages,
  ICertification,
  IWorkExperience,
  MasterProfileStep,
  TestSteps,
} from './admission.constants';
import {IProfileData} from './admission.reducer';

export const loadStudentData = createAction('[Admission] Load Student Data', (onActionCompletion?) => ({
  onActionCompletion,
}));

export const loadStudentDataSuccess = createAction('[Admission] Load Student Data Success', (studentData: any) => ({
  studentData,
}));

export const updateStudentDataSuccess = createAction(
  '[Admission] Update Student Data Success',
  (updateFields: any, leadDataReqs?) => ({updateFields, leadDataReqs}),
);

export const loadStudentDataFailure = createAction('[Admission] Update Student Data Failure');

export const saveHighestQualification = createAction(
  '[Admission] Save Highest Education',
  (highestQualification: number) => ({highestQualification}),
);

export const saveEducationDetails = createAction('[Admission] Save Education Details', (educationData: any) => ({
  educationData,
}));


export const saveDocumentDetailsError = createAction('[Admission] Save Document Details Error');

export const savePersonalDetails = createAction('[Admission] Save Personal Details', (personalData: any) => ({
  personalData,
}));

export const saveBaseLeadDetails = createAction('[Admission] Save Base Lead Details Details', (baseData: any) => ({
  baseData,
}));

export const updateEligibilityStep = createAction('[Admission] Update Eligibility Step', (step: string) => ({step}));

export const updateCampusSelectStep = createAction('[Admission] Update Campus Select Step', (step: string) => ({step}));

export const updateTestStep = createAction('[Admission] Update Test Step', (step: TestSteps) => ({step}));

export const updateCampusSelectData = createAction('[Admission] Update Campus Select Data', (data: any) => ({data}));

export const loadEligiblePrograms = createAction('[Admission] Load Eligible Programs');

export const loadEligibleProgramsSuccess = createAction(
  '[Admission] Load Eligible Programs Success',
  (currentProgram, programs: any[]) => ({currentProgram, programs}),
);

export const loadEligibleProgramsError = createAction('[Admission] Load Eligible Programs Error');

export const loadEligibleCampuses = createAction(
  '[Admission] Load Eligible Campuses',
  (programId: number, batch: string, cityId: number) => ({programId, batch, cityId}),
);

export const loadEligibleCampusesSuccess = createAction(
  '[Admission] Load Eligible Campuses Success',
  (campuses, noCampusesInSelectedCity) => ({campuses, noCampusesInSelectedCity}),
);

export const loadEligibleCampusesError = createAction('[Admission] Load Eligible Campuses Error');

export const loadPaymentPlans = createAction(
  '[Admission] Load Payment Plans',
  (programId: number, campusId: number) => ({programId, campusId}),
);

export const loadPaymentPlansSuccess = createAction('[Admission] Load Payment Plans Success', (paymentPlans) => ({
  paymentPlans,
}));

export const loadPaymentPlansError = createAction('[Admission] Load Payment Plans Error');

export const saveCampusSelection = createAction(
  '[Admission] Save Campus Selection',
  (programId, campusId, paymentId, paymentProgramType) => ({programId, campusId, paymentId, paymentProgramType}),
);

export const toggleProfileDrawer = createAction(
  '[Admission] Toggle Profile Drawer',
  (profileDrawerState?: boolean) => ({profileDrawerState}),
);

export const loadStates = createAction('[Admission] Load States');

export const loadStatesSuccess = createAction('[Admission] Load States Success', (states) => ({states}));

export const loadStatesError = createAction('[Admission] Load States Error');

export const loadCities = createAction('[Admission] Load Cities');

export const loadCitiesSuccess = createAction('[Admission] Load Cities Success', (cities) => ({cities}));

export const loadCitiesError = createAction('[Admission] Load Cities Error');

export const setMasterProfileStep = createAction('[Admission] Set Master Profile Step', (step: MasterProfileStep) => ({
  step,
}));

export const deleteLeadDataReq = createAction(
  '[Admission] Delete Lead Data Req',
  (docKey: string, onActionCompletion?: any) => ({docKey, onActionCompletion}),
);

export const deleteLeadDataReqSuccess = createAction('[Admission] Delete Lead Data Req Success', (docKey: string) => ({
  docKey,
}));

export const deleteLeadDataReqError = createAction('[Admission] Delete Lead Data Req Error');

export const setDocFormEditId = createAction('[Admission] Set Doc Form Edit Id', (docFormEditId: number) => ({
  docFormEditId,
}));

export const loadProfileCompletionPercentage = createAction('[Admission] Load Profile Completion Percentage');
export const loadProfileCompletionPercentageSuccess = createAction(
  '[Admission] Load Profile Completion Percentage Success',
  (profileCompletionPercentage: number) => ({profileCompletionPercentage}),
);

export const loadProfileCompletionPercentageError = createAction(
  '[Admission] Load Profile Completion Percentage Error',
);

export const clearAdmissionStore = createAction('[Admission] Clear admission store');

export const loadCounsellorNumber = createAction('[Admission] Load Counsellor Number', (counsellorId: number) => ({
  counsellorId,
}));

export const setCounsellorNumber = createAction(
  '[Admission] Set Counsellor Number',
  (counsellorPhoneNumber: string) => ({
    counsellorPhoneNumber,
  }),
);

import {NativeActions} from '../admission-new/shared/admission.constants';
import {WorkFromHome} from './shared.enums';

// Defining common platforms, all others can be grouped into one of these
export enum PlatformType {
  ios,
  android,
  windows,
  mac,
  linux,
}

export enum BrowserType {
  chrome = 'chrome',
  firefox = 'firefox',
  safari = 'safari',
  opera = 'opera',
  edge = 'edge',
  vivo = 'vivo',
  oppo = 'oppo',
  fallback = 'fallback',
}

export const elegantScrollbarStyle = `
body::-webkit-scrollbar {
    background-color:transparent;
    width:16px
}

body::-webkit-scrollbar-track {
    background-color:transparent;
}

body::-webkit-scrollbar-track:hover {
    background-color:#fff;
}

body::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:4px solid #fff;
}

body::-webkit-scrollbar-button {
    display:none;
}`;

export const responsiveBodyStyle = `
html { font-size: 10px}

@media all and (max-width:768px) {
  html { font-size: 8px}
}
`;

export const deviceBreakpoints = {
  mobile: 600,
  tablet: 768,
  desktop: 992,
  desktopLg: 1200,
};

export interface IApiResponse {
  success: boolean;
  message: string;
  data: any;
}

export interface IJob {
  city_name: string;
  company_name: string;
  compensation_show: string;
  designation: string;
  id: number;
  is_bookmarked: boolean;
  is_eligible: string;
  launched_date: string;
  logo: string;
  no_of_vacancies: number;
  opportunity_id: number;
  role_show: string;
  is_applied?: string | number;
  not_eligible_reason?: object[];
  webLink?: string;
  is_deadline_done?: boolean;
  not_applied_reason?: Array<string>;
  deadline?: string;
  reason_for_debard?: string;
  final_status?: string | null;
  offer_id?: number | null;
  is_accepted?: string | null;
  reason?: Array<any>;
  message: string;
  can_apply: boolean;
}

export interface IOpportunityAssignment {
  description: string;
  map_id: number;
  sample_assignment_url: string;
  assignment_url: string;
  submission_date: string;
}

export type OpportunityStatus = 'ongoing' | 'selected' | 'rejected';

export interface SunstoneProgram {
  id: number;
  name: string;
}

/**
 * @deprecated - use only in case of fallbacks
 */
export enum SunstoneProgramIds {
  MBA_PGDM = '1',
  PGDM = '2',
  BBA = '3',
  GLOBAL_IMMERSION_MBA = '4',
  GLOBAL_IMMERSION_PGDM = '5',
  GLOBAL_IMMERSION_BBA = '6',
  MCA = '7',
  ONLINE_MBA = '9999',
  PGDM_Financial_Management_MIT = '8',
  MBA_General_Management_Jain_University = '10',
  PGDM_Marketing_MIT = '11',
  BCA = '12',
  BCOM = '13',
  BTECH = '14',
}

export const SunstoneOfflineUgPrograms = [
  SunstoneProgramIds.BBA,
  SunstoneProgramIds.BCA,
  SunstoneProgramIds.GLOBAL_IMMERSION_BBA,
  SunstoneProgramIds.BTECH,
  SunstoneProgramIds.BCOM,
];
export const SunstoneOfflinePgPrograms = [
  SunstoneProgramIds.MBA_PGDM,
  SunstoneProgramIds.PGDM,
  SunstoneProgramIds.MCA,
  SunstoneProgramIds.GLOBAL_IMMERSION_MBA,
  SunstoneProgramIds.GLOBAL_IMMERSION_PGDM,
];
export const SunstoneOnlinePgPrograms = [
  SunstoneProgramIds.ONLINE_MBA,
  SunstoneProgramIds.PGDM_Financial_Management_MIT,
  SunstoneProgramIds.MBA_General_Management_Jain_University,
  SunstoneProgramIds.PGDM_Marketing_MIT,
];

export enum EDUCATION_LEVELS {
  Graduate = 'Graduation',
  PursuingGraduation = 'Pursuing Graduation',
  XIIPursuing = 'Pursuing 12th',
  XII = '12th Pass',
  X = '10th Pass',
  Diploma = 'Diploma',
  PostGraduate = 'Post-Graduate',
}

export const EDUCATION_LEVELS_ORDERED = [
  EDUCATION_LEVELS.Graduate,
  EDUCATION_LEVELS.PursuingGraduation,
  EDUCATION_LEVELS.XII,
  EDUCATION_LEVELS.XIIPursuing,
  EDUCATION_LEVELS.Diploma,
  EDUCATION_LEVELS.PostGraduate,
  EDUCATION_LEVELS.X,
];

export type EducationLevelTypes =
  | 'Graduation'
  | 'Pursuing Graduation'
  | 'Pursuing 12th'
  | '12th Pass'
  | '10th Pass'
  | 'Diploma'
  | 'Post-Graduate';

export const UgEducationLevels: EducationLevelTypes[] = ['Pursuing 12th', '12th Pass', '10th Pass', 'Diploma'];
export const PgEducationLevels: EducationLevelTypes[] = ['Graduation', 'Pursuing Graduation', 'Post-Graduate'];

export enum EducationFormIds {
  graduation = 1,
  graduationOngoing = 2,
  X = 3,
  XII = 4,
  XIIOngoing = 5,
  workExperience = 6,
  aadhaar = 7,
  specialCategory = 8,
  passportPhoto = 9,
}

export enum StudentCategory {
  GENERAL = '1',
  SC_ST_OBC = '2',
  PHYSICALLY_CHALLENGED = '3',
}

export const StudentCategoryNames = {
  1: 'General',
  2: 'SC/ST/OBC',
  3: 'Physically Challenged',
};

export enum ProfileCompletion {
  NEW_LEAD = '1',
  ELIGIBLE = '1.1',
  NOT_ELIGIBLE = '1.2',
  NOT_IN_USE = '2',
  DOCUMENT_UPLOADED = '3',
  DOCUMENT_VERIFIED = '4',
  APPLICATION_FORM_COMPLETED = '5',
  TEST_SCHEDULED = '6',
  // show new card
  TEST_GIVEN = '7',
  // show new card
  INTERVIEW_SCHEDULED = '8',
  STUDENT_SELECTED = '9',
  OFFER_LETTER_REQUESTED = '10',
  SEAT_BOOKED = '11',
  CLOSED_LOST = '12',
  REFUND = '13',
  INTERVIEW_APPEARED = '14',
  ALL_AMOUNT_PAID = '15',
  OFFER_LETTER_RELEASED = '16',
  INTERVIEW_SELECTION_REJECTED = '17',
  OFFER_LETTER_ACCEPTED = '18',
  PAP_AGREEMENT_SIGNED = '19',
}

export enum StepForm {
  SELECT_PROGRAM = 1,
  COMPLETE_FORM = 2, // To complete form
  TEST_INTRO = 3,
  TEST_SCHEDULE = 4,
  TEST_READY = 5,
  TEST_CONFIRM = 6,
  INTERVIEW_SCREEN = 7,
  SCHEDULE_INTERVIEW = 8, // NOT IN USE AS OF NOW
  INTERVIEW_SCREEN_2 = 9,
  DOCUMENTATION = 13,
}

// the student can apply to this academic year
export const EligibleYear = 2023;
export const xYearsInFuture = (X) => new Date(new Date().setFullYear(new Date().getFullYear() + X)).getFullYear();
const today = new Date();
export const currentYear = new Date().getFullYear();
const limitDate = new Date(currentYear, 3);
export const EducationYearsRange = {min: 2000, max: xYearsInFuture(3)};
export const FutureEducationYearsRange = {min: currentYear, max: xYearsInFuture(3)};
export const PastEducationYearsRange = {min: 2000, max: today > limitDate ? currentYear : currentYear - 1};

export enum SCORE_TYPE {
  percentage = 1,
  gpa = 2,
}

export const EducationDocuments = {
  highestEducation: 'Highest education marksheet',
  XII: '12th marksheet',
  XIIOngoing: '12th/11th marksheet certificate',
  X: '10th marksheet',
  ID: 'Aadhar Card/ID Proof (to prove your identity)',
  special: 'Category proof',
  photo: 'Passport size photograph',
  workExp: null,
};

export interface ICity {
  id: number;
  name: string;
  state_id: number;
}

export interface IState {
  id: number;
  name: string;
}

export enum PaymentType {
  CAMPUS = 'CAMPUS',
  SUNSTONE = 'SUNSTONE',
  ALL = 'ALL',
}

export const MINIMUM_PAYMENT = 1;

export interface IOfferLetterData {
  joining_date: string;
  payment_program_id: number;
  payment_program_name: string;
  created: string;
  campus_scholarship_amount: number;
  campus_offer_letter_url: string;
  sunstone_fee_total: any;
  pap_multiplier: any;
  sunstone_scholarship_amount: number;
  maximum_cap: number;
  opt_out_fee: number;
  campus_deferred_amount: number;
  sunstone_offer_letter_url: any;
  sunstone_deferred_amount: number;
  campus_fee_total: any;
  user_id: number;
  payment_scheme_id: number;
  modified: string;
  id: number;
  status: string;
}

export const WORK_FROM_HOME_DICT = {
  [WorkFromHome.NO]: 'No',
  [WorkFromHome.YES]: 'Yes',
  [WorkFromHome.HYBRID]: 'Hybrid',
};

export const WEBVIEW_USER_AGENT_NAME = 'SUNSTONE_WEBVIEW';
export const WEBVIEW_USER_AGENT_NAME_IOS = 'flutter_inappwebview';

export interface WebviewAction {
  action: NativeActions;
  onActionSuccess: string;
  onActionFailure: string;
}

export const UG_PROGRAMS_2023 = [
  {
    id: '3',
    name: 'BBA',
  },
  {
    id: '12',
    name: 'BCA',
  },
  {
    id: '14',
    name: 'BTECH',
  },
];

export const PG_PROGRAMS_2023 = [
  {
    id: '1',
    name: 'MBA',
  },
  {
    id: '7',
    name: 'MCA',
  },
];

export const ADMISSION_PENDING_STATE_COOKIE = 'ADMISSIONPENDINGSTATE';

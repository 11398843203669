import {Injectable} from '@angular/core';
import {BrowserType, PlatformType} from '../shared/shared.constants';
import {AdmissionService} from '../admission-new/shared/services/admission.service';
import {ProgramService} from '../admission-new/shared/services/program.service';
import {StatusBarService} from '../admission-new/status-bar/status-bar.service';
import {take} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  userPlatform: PlatformType;
  uploadCtaChange = false;

  constructor(
    private admissionService: AdmissionService,
    private programService: ProgramService,
    private statusBarService: StatusBarService,
  ) {
    this.determineUserPlatform();
  }

  determineUserPlatform(): void {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (macosPlatforms.indexOf(platform) !== -1) {
      this.userPlatform = PlatformType.mac;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      this.userPlatform = PlatformType.ios;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      this.userPlatform = PlatformType.windows;
    } else if (/Android/.test(userAgent)) {
      this.userPlatform = PlatformType.android;
    } else if (!this.userPlatform && /Linux/.test(platform)) {
      this.userPlatform = PlatformType.linux;
    }
  }

  determineUserBrowser(): BrowserType {
    const userAgent = window?.navigator?.userAgent;
    if (userAgent.match(/vivoBrowser/i)) {
      return BrowserType.vivo;
    } else if (userAgent.match(/oppo/i)) {
      return BrowserType.oppo;
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
      return BrowserType.chrome;
    } else if (userAgent.match(/firefox|fxios/i)) {
      return BrowserType.firefox;
    } else if (userAgent.match(/safari/i)) {
      return BrowserType.safari;
    } else if (userAgent.match(/opr\//i)) {
      return BrowserType.opera;
    } else if (userAgent.match(/edg/i)) {
      return BrowserType.edge;
    } else {
      return BrowserType.fallback;
    }
  }

  /**
   * Utility function to add CSS in multiple passes.
   */
  injectStyle(styleString: string): void {
    const style = document.createElement('style');
    style.textContent = styleString;
    document.head.append(style);
  }

  range(min, max): any[] {
    return [...Array(max - min + 1).keys()].map((i) => i + min);
  }

  obfuscatePhoneNumber(phoneNumber): string {
    if (phoneNumber.length === 13) {
      const start = phoneNumber.slice(3, 5);
      const end = phoneNumber.slice(11, 13);
      return `+91 ${start}XXXXXX${end}`;
    }
  }

  sleep(ms): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getImageFormUrl(url, callback): void {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = function (a) {
      const canvas = document.createElement('canvas');
      canvas.width = this['width'];
      canvas.height = this['height'];
      const ctx = canvas.getContext('2d');
      (ctx as any).drawImage(this, 0, 0);

      const dataURI = canvas.toDataURL('image/jpg');

      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
      } else {
        byteString = unescape(dataURI.split(',')[1]);
      }

      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return callback(new Blob([ia], {type: mimeString}));
    };

    img.src = url;
  }

  deepCopy(item): any {
    return JSON.parse(JSON.stringify(item));
  }

  stripHtml(html): string | undefined {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  scrollToTop(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  stopScroll(): void {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  startScroll(): void {
    document.getElementsByTagName('body')[0].style.overflow = 'visible';
  }
  async getCurrentProgramName(): Promise<String> {
    const data = await this.statusBarService.widgetData.pipe(take(1)).toPromise();
    if (data?.program === 'BTECH') {
      return 'BTech';
    }
    return data?.program;
  }
}


interface MoengageType {
  destroy_session: () => void;
  track_event: (event: any, params: any) => void;
  add_user_attribute: (attributeName: string, atrributes: Record<string, any>) => void;
  add_unique_user_id: (id: string) => void;
  update_unique_user_id: (id: string) => void;
}

export function Moengage(): undefined | MoengageType {
  if (window && window?.['Moengage']) {
    return window['Moengage'] as MoengageType;
  }
  return undefined;
}

import {CampusDetails} from '../admission.constants';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {format} from 'date-fns';
import {lastValueFrom, Observable, of, Subject} from 'rxjs';
import {catchError, pluck} from 'rxjs/operators';
import {TIME_FORMATS} from 'src/app/constants/time';
import {environment} from 'src/environments/environment';
import {AppService} from '../../../services/app.service';
import {ICity} from '../../../shared/shared.constants';
import {updateStudentDataSuccess, updateTestStep} from '../admission.actions';
import {SUNSTONE_TEST_VARIANT, TestSteps} from '../admission.constants';
import {AdmissionState} from '../admission.reducer';
import {TaskExecutorService} from '../../modules/lead-journey/task-executor.service';
import {StageNames, TaskNames} from '../../modules/lead-journey/stage-resolver.enums';
import {getStageTasks} from '../../modules/lead-journey/stage-resolver.utils';
import {StageResolverService} from '../../modules/lead-journey/stage-resolver.service';

@Injectable({
  providedIn: 'root',
})
export class AdmissionService {
  testData = {
    sections: [],
    extra_details: [],
    attempts_details: {
      attempts: 0,
      total_questions_left: '',
      total_time_left: 1800,
    },
    questionCount: 25,
    total_test_time: 30,
    test_sample_pdf: [],
    test_sample_pdf_zip: '',
    test_attempt_count: 5752,
    test: null,
    is_test_given: false,
  };

  openScheduler = new Subject();
  openConfirmator = new Subject();
  scholarshipBannerShown = true;

  cityList = [];
  stateList = [];
  testId: any;

  constructor(
    private appService: AppService,
    private store: Store<AdmissionState>,
    private http: HttpClient,
    private taskExecutorService: TaskExecutorService,
    private stageResolver: StageResolverService,
  ) {}

  get tmpFilePrefix(): string {
    return `${this.appService.s3Url}placement/temp_images/`;
  }

  async getTestQuestions(testId = SUNSTONE_TEST_VARIANT.SEAT, getQuestions = false): Promise<any> {
    try {
      this.appService.showLoader = true;
      const res = await this.appService.getApi(`sat/getTestInformation?get_questions=${getQuestions ? '1' : '0'}`);
      const testGivenRes = await this.appService.getApi(`sat/v2/getTestGivenUsers`);
      this.appService.showLoader = false;
      if (testGivenRes && testGivenRes.success) {
        this.testData.test_attempt_count = testGivenRes.data;
      }
      if (res && res.success) {
        this.testId = res.data.test.id;
        this.testData.sections = res.data.sections;
        this.testData.extra_details = res.data.extra_details;
        this.testData.attempts_details = res.data.attempts_details;
        if (this.testData.attempts_details.attempts > 0) {
          this.store.dispatch(updateTestStep(TestSteps.TEST_READY));
        }
        this.testData.questionCount = res.data.sections.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.total_questions;
        }, 0);
        this.testData.test_sample_pdf = res.data.test_sample_pdf;
        this.testData.test_sample_pdf_zip = res.data.test_sample_pdf_zip;
        this.testData.total_test_time = Math.floor(res.data.test?.duration / 60);
        this.testData.test = res.data.test;
        this.testData.is_test_given = res.data.is_test_given;
        return res.data;
      }
      return false;
    } catch (e) {
      this.appService.openToast('Could not initialise test, please try again later', 'Ok');
      this.appService.showLoader = false;
      return false;
    }
  }

  async scheduleTest(scheduleDate: Date): Promise<boolean> {
    const formattedDate = format(scheduleDate, TIME_FORMATS.DATE_AND_TIME_24);
    this.store.dispatch(updateStudentDataSuccess({schedule_test_time: formattedDate}));
    try {
      this.appService.showLoader = true;
      const leadJourney = this.stageResolver.leadJourney;
      const stageTasks = getStageTasks(StageNames.TEST, leadJourney);
      if (stageTasks.includes(TaskNames.SCHEDULE_TEST)) {
        await lastValueFrom(
          this.taskExecutorService.executeTask(
            TaskNames.SCHEDULE_TEST,
            {
              scheduleDate,
            },
            false,
          ),
        );
      } else if (stageTasks.includes(TaskNames.RESCHEDULE_TEST)) {
        await lastValueFrom(
          this.taskExecutorService.executeTask(
            TaskNames.RESCHEDULE_TEST,
            {
              scheduleDate,
            },
            false,
          ),
        );
      }
      this.appService.showLoader = false;
      return true;
    } catch (e) {
      console.log('Could not schedule test');
      this.appService.showLoader = false;
      return false;
    }
  }

  requestScheduler(): void {
    this.openScheduler.next(true);
  }

  requestConfirmator(): void {
    this.openConfirmator.next(true);
  }

  async uploadTempDoc($event: any, key: string): Promise<any> {
    const element = $event.target;
    const documentFile = element.files && element.files[0];
    const uploadForm = new FormData();
    uploadForm.set('file', documentFile);
    uploadForm.set('filename', key);
    try {
      const res = await this.appService.postApi('users/uploadTempDocs', uploadForm);
      if (res.success) {
        return res.data;
      } else {
        this.appService.openToast(res.message || 'Something went wrong. Please try again.', 'Ok');
      }
    } catch (err) {
      this.appService.openToast(
        (err && err.error && err.error.message) || 'Something went wrong. Please try again.',
        'Ok',
      );
    }
  }

  uploadTempFile(file: File, key: string): Observable<any> {
    const uploadForm = new FormData();
    uploadForm.set('file', file);
    uploadForm.set('filename', key);
    return this.http.post(`${environment.urls.host}users/uploadTempDocs`, uploadForm).pipe(pluck('data'));
  }

  async fetchCityList(): Promise<ICity[]> {
    if (this.cityList.length) {
      return this.cityList;
    }
    try {
      const res = await this.appService.getApi('api/fetchCityList');
      if (res.success) {
        this.cityList = res.data.map((city) => ({id: city.id, name: city.name, state_id: city.state_id}));
        return res.data;
      } else {
      }
    } catch (err) {}
  }

  async fetchStateList(): Promise<any> {
    if (this.stateList.length) {
      return this.stateList;
    }
    try {
      const res = await this.appService.getApi('api/fetchStateList');
      if (res.success) {
        this.stateList = res.data;
        return res.data;
      } else {
      }
    } catch (err) {}
  }

  async checkEmailExists(email): Promise<any> {
    try {
      return await this.appService.admitgetApi(`agent/checkEmailExist?email=${email}`);
    } catch (err) {
      return false;
    }
  }
  async updateName(name: string): Promise<any> {
    try {
      this.store.dispatch(updateStudentDataSuccess({name: name}));
    } catch (err) {
      return false;
    }
  }

  async sendOtp(payload): Promise<any> {
    try {
      const res = await this.appService.postApi('student/sendOtp', payload);
      return res.success;
    } catch (err) {
      return false;
    }
  }

  async verifyEmailOtp(email, otp): Promise<any> {
    try {
      const payload = {
        email,
        otp,
      };
      const res = await this.appService.admitpostApi('agent/verifyDetailsWithOtp', payload);
      if (res.success) {
        return res?.data ? res.data : {type: 'lead'};
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  async verifyWhatsappOtp(whatsapp, otp): Promise<any> {
    try {
      const payload = {
        whatsapp_no: whatsapp,
        otp,
      };
      const res = await this.appService.admitpostApi('agent/verifyDetailsWithOtp', payload);
      return res.success;
    } catch (err) {
      return false;
    }
  }

  async fetchPopularCities(): Promise<ICity[]> {
    try {
      const url = 'lead/popularCities';
      const res = await this.appService.getApi(url);
      if (res.success) {
        return res.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  }

  async leadAccountMerge(email, type): Promise<boolean> {
    this.appService.showLoader = true;
    const payload = {email, type};
    try {
      const res = await this.appService.admitpostApi('agent/leadAccountMerge', payload);
      if (res.success) {
        if (type === '1') {
          const user = {
            id: res.data.id,
            name: res.data.name,
            session_key: res.data.session_key,
            email: res.data.email,
            role: res.data.role,
          };
          this.appService.cookie.put('alu', JSON.stringify(user), {
            expires: new Date(new Date().setDate(new Date().getDate() + 90)),
            domain: '.sunstone.in',
          });
          this.appService.cookie.put('alu', JSON.stringify(user), {
            expires: new Date(new Date().setDate(new Date().getDate() + 90)),
          });
          if (this.appService.isBrowser()) {
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }
          window.location.reload();
        }
        this.appService.showLoader = false;
        return true;
      } else {
        this.appService.showLoader = false;
        return false;
      }
    } catch (e) {
      this.appService.showLoader = false;
      return false;
    }
  }

  async fetchGradDegreeList(): Promise<string[]> {
    try {
      const res = await this.appService.getApi('api/fetchGraduationDegreeList');
      if (res.success) {
        return res.data;
      }
      return [];
    } catch (e) {
      return [];
    }
  }

  /**
   *
   * @returns empty string if there's an error fetching the number
   */
  fetchCounsellorNumber() {
    return this.http
      .get(`${environment.urls.host}panelUser/getCounsellorNumber`, {headers: this.appService.apiheader})
      .pipe(
        pluck('data'),
        catchError((error: HttpErrorResponse) => {
          return of('');
        }),
      );
  }

  fetchCampusDetails(): Observable<{campus?: CampusDetails; popularCampuses?: CampusDetails[]}> {
    return this.http.get(`${environment.urls.host}user/campus-details`, {headers: this.appService.apiheader}).pipe(
      pluck('data'),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      }),
    );
  }
  async fetchHelpFAQ(): Promise<any> {
    try {
      const res = await this.appService.getApi('faq/getHubHelp');
      if (res.success) {
        return res.data;
      }
      return [];
    } catch (e) {
      return [];
    }
  }
  async sendAppDownloadLink(): Promise<any> {
    try {
      const res = await this.appService.getApi('user/downloadapp-sendWhatsapp');
      if (res?.success) {
        return res?.data;
      }
    } catch (e) {
      return e;
    }
  }
  async getAppLink() {
    const res = await this?.sendAppDownloadLink();
    this?.appService?.openToast(res ? `App link sent to your whatsapp number` : `oops something went wrong`);
  }
}

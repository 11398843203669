import {NavigationEnd, Router} from '@angular/router';
import mixpanel from 'mixpanel-browser';
import {environment} from './environments/environment';
import {getWebviewContext} from './app/services/auth.helpers';

function handleClickEvent(event: MouseEvent) {
  if (!window.location.pathname.includes('apply-now')) {
    const target = event.target as HTMLElement;

    if (target.nodeName === 'BUTTON') {
      mixpanel.track(ANALYTICS_EVENT_NAMES.BUTTON_CLICK, {
        cta: target.innerText,
        page_path: window.location.pathname,
        identifier: target.attributes.getNamedItem?.('data-aid')?.value || 'Not Provided',
      });
    }
  }
}

export function initializeAnalytics(router: Router) {
  if (window) {
    mixpanel.init(environment.mixpanelToken, {debug: !environment.production});
    window.document.addEventListener('click', handleClickEvent);
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        mixpanel.track(ANALYTICS_EVENT_NAMES.PAGE_VIEW, {page_path: window.location.pathname});
      }
    });
  }
}

export function unregisterAllAnalyticsEventListeners() {
  if (window) {
    window.document.removeEventListener('click', handleClickEvent);
  }
}

export enum ANALYTICS_EVENT_NAMES {
  VIDEO_PLAY = 'video_play',
  BUTTON_CLICK = 'button_click',
  PAGE_VIEW = 'page_view',
  LOGGED_IN_VIA_WHATSAPP = 'logged_in_via_whatsapp',
}
export enum DataLayerProperties {
  EVENT = 'event',
  USER_ID = 'user_id',
  MOBILE = 'mobile',
  EMAIL = 'email',
  NAME = 'name',
  PROGRAM = 'program',
  STATE = 'state',
  UTM_SOURCE = 'utm_source',
  UTM_CAMPAIGN = 'utm_campaign',
  ROLE = 'role',
  UTM_MEDIUM = 'utm_medium',
  IS_WEB_VIEW = 'is_webview',
}

export function loginToMixpanel(userDetails: any) {
  if (userDetails?.id) {
    mixpanel.identify(userDetails.id?.toString());
    mixpanel.people.set({
      [DataLayerProperties.USER_ID]: userDetails?.id,
      [DataLayerProperties.MOBILE]: userDetails?.mobile,
      [DataLayerProperties.EMAIL]: userDetails?.email,
      [DataLayerProperties.NAME]: userDetails?.name,
      [DataLayerProperties.PROGRAM]: userDetails?.program,
      [DataLayerProperties.STATE]: userDetails?.state,
      [DataLayerProperties.ROLE]: userDetails?.role,
    });
  }
}

export function pushUserDetailsToDataLayer(userDetails: any) {
  const isWebView = !Boolean(getWebviewContext());
  if (userDetails) {
    pushToDataLayer({
      [DataLayerProperties.EVENT]: 'page_load_data_layer_push',
      [DataLayerProperties.USER_ID]: userDetails?.id,
      [DataLayerProperties.MOBILE]: userDetails?.mobile,
      [DataLayerProperties.EMAIL]: userDetails?.email,
      [DataLayerProperties.NAME]: userDetails?.name,
      [DataLayerProperties.PROGRAM]: userDetails?.program,
      [DataLayerProperties.STATE]: userDetails?.state,
      [DataLayerProperties.ROLE]: userDetails?.role,
      [DataLayerProperties.IS_WEB_VIEW]: isWebView,
    });
  }
}

export type DataLayerItem = Partial<Record<DataLayerProperties, any>>;

export function getDataLayer(): DataLayerItem[] | undefined {
  const _window = window as any;
  if (_window && _window.dataLayer) {
    return _window.dataLayer;
  } else {
    return undefined;
  }
}
/**
 * Adds `data` to GTM's `dataLayer`
 * @param data
 */
export function pushToDataLayer(data: DataLayerItem) {
  const dataLayer = getDataLayer();
  if (dataLayer) {
    dataLayer.push(data);
  } else {
    console.warn('Could not find the dataLayer');
  }
}

export function trackMixpanelEvent(eventName: ANALYTICS_EVENT_NAMES, eventData?: Record<string, string>) {
  mixpanel.track(eventName, {
    page_path: window.location.pathname,
    ...eventData,
  });
}
export function triggerNestedButtonMixPanelEvent(ctaName = null, dataAid = null) {
  if (window) {
    const target = event.target as HTMLElement;
    const elementIdentifier = target.getAttribute('data-aid') ?? dataAid;
    mixpanel.track(ANALYTICS_EVENT_NAMES.BUTTON_CLICK, {
      cta: ctaName || target.innerText,
      page_path: window.location.pathname,
      identifier: elementIdentifier || 'Not Provided',
    });
  }
}

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {CookieModule} from 'ngx-cookie';
import {FacebookModule} from 'ngx-facebook';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {metaReducers, reducers} from './reducers';
import {ErrorInterceptor} from './shared-core/error-interceptor';
import * as Sentry from '@sentry/angular';
import {HTTP_INTERCEPTOR_PROVIDERS} from './http/interceptors';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    TransferHttpCacheModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    CookieModule.forRoot(),
    HttpClientModule,
    BrowserTransferStateModule,
    StoreModule.forRoot({}, {}),
    StoreModule.forRoot(reducers, {metaReducers}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    FacebookModule.forRoot(),
    EffectsModule.forRoot([]),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    HTTP_INTERCEPTOR_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

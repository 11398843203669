import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AppService} from '../../services/app.service';
import {ADMISSION_PENDING_STATE_COOKIE} from '../../shared/shared.constants'
import {FlutterRedirectionService} from '../../admission-new/shared/services/flutter-redirection.service';

@Injectable({
  providedIn: 'root',
})
export class AdmissionGuard implements CanActivate {
  constructor(
    private appService: AppService,
    private router: Router,
    private flutterRedirectionService: FlutterRedirectionService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let checkAuth: any
    console.log('?????? INSIDE ADMISSION GUARD')
    const isFlutterActive = this.flutterRedirectionService.checkIfFlutterUser()
    console.log(`?????? INSIDE ADMISSION GUARD - isFlutterActive`, isFlutterActive);
    if(isFlutterActive){
      console.log(`?????? INSIDE ADMISSION GUARD - ACTIVATING NEW LOGIN FLOW`);
      checkAuth = await this.appService.checkAdmission();
    } else {
      console.log(`?????? INSIDE ADMISSION GUARD - ACTIVATING OLD LOGIN FLOW`);
      checkAuth = await this.appService.checkAdmissionOld();
    }
    if (!checkAuth) {
      console.log(`?????? INSIDE ADMISSION GUARD - AUTH NOT FOUND GOING TO LOGIN`);
      this.appService.cookie.put(ADMISSION_PENDING_STATE_COOKIE, state.url, {domain: '.sunstone.in'});
      // Because angular removes the queryparams when we return a UrlTree for some reason
      this.router.navigate([''], {queryParams: next.queryParams});
    } else {
      console.log(`?????? INSIDE ADMISSION GUARD - AUTH FOUND`);
      // this.appService.cookie.remove(ADMISSION_PENDING_STATE_COOKIE, {domain: '.sunstone.in'});
      return true;
    }
  }
}

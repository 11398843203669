import {environment} from 'src/environments/environment';
import {AppService} from 'src/app/services/app.service';
import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import { getUserDetailsFromWebviewContextOrCookie } from 'src/app/services/auth.helpers';

@Injectable({
  providedIn: 'root',
})
export class ClassLoginGuard implements CanActivate {
  constructor(public appService: AppService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let checkAuth: boolean = true;
    let userData = getUserDetailsFromWebviewContextOrCookie(this.appService.cookie)
    // if (this.appService.cookie && (this.appService.cookie.get('psu') || this.appService.cookie.get('alu'))) {
    //   if (this.appService.checkLogin()) {
    //     checkAuth = false;
    //   }
    //   checkAuth = true;
    // } else if (this.appService.cookie && this.appService.cookie.get('ppu')) {
    //   window.location.href = environment.urls.consoleUrl;
    //   checkAuth = false;
    // }
    if (userData) {
      if (this.appService.checkLogin()) {
        checkAuth = false;
      }
      checkAuth = true;
    } else if (this.appService.cookie && this.appService.cookie.get('ppu')) {
      window.location.href = environment.urls.consoleUrl;
      checkAuth = false;
    }
    return checkAuth;
  }
}

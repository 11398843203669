import {format} from 'path';

export const TIME_FORMATS = {
  DATE_ONLY: 'yyyy-LL-dd',
  DATE_AND_TIME_24: 'yyyy-LL-dd HH:mm:ss',
  DATE_AND_TIME_12: 'yyyy-LL-dd hh:mm:ss',
  TIME_HUMAN_READABLE: 'hh:mm aa',
  DATE_xx: `dd MMM yyyy, hh:mm a`,
};

export const TIME_INTERVALS_IN_A_DAY_FULL: {label: string; value: string}[] = [
  {value: '00:00:00', label: '12AM-1AM'},
  {value: '01:00:00', label: '1AM-2AM'},
  {value: '02:00:00', label: '2AM-3AM'},
  {value: '03:00:00', label: '3AM-4AM'},
  {value: '04:00:00', label: '4AM-5AM'},
  {value: '05:00:00', label: '5AM-6AM'},
  {value: '06:00:00', label: '6AM-7AM'},
  {value: '07:00:00', label: '7AM-8AM'},
  {value: '08:00:00', label: '8AM-9AM'},
  {value: '09:00:00', label: '9AM-10AM'},
  {value: '10:00:00', label: '10AM-11AM'},
  {value: '11:00:00', label: '11AM-12PM'},
  {value: '12:00:00', label: '12PM-1PM'},
  {value: '13:00:00', label: '1PM-2PM'},
  {value: '14:00:00', label: '2PM-3PM'},
  {value: '15:00:00', label: '3PM-4PM'},
  {value: '16:00:00', label: '4PM-5PM'},
  {value: '17:00:00', label: '5PM-6PM'},
  {value: '18:00:00', label: '6PM-7PM'},
  {value: '19:00:00', label: '7PM-8PM'},
  {value: '20:00:00', label: '8PM-9PM'},
  {value: '21:00:00', label: '9PM-10PM'},
  {value: '22:00:00', label: '10PM-11PM'},
  {value: '23:00:00', label: '11PM-12AM'},
];
export const TIME_INTERVALS_IN_A_DAY_HALF: {label: string; value: string}[] = [
  {value: '00:00:00', label: '12AM-12:30AM'},
  {value: '00:30:00', label: '12:30AM-1AM'},
  {value: '01:00:00', label: '1AM-1:30AM'},
  {value: '01:30:00', label: '1:30AM-2AM'},
  {value: '02:00:00', label: '2AM-2:30AM'},
  {value: '02:30:00', label: '2:30AM-3AM'},
  {value: '03:00:00', label: '3AM-3:30AM'},
  {value: '03:30:00', label: '3:30AM-4AM'},
  {value: '04:00:00', label: '4AM-4:30AM'},
  {value: '04:30:00', label: '4:30AM-5AM'},
  {value: '05:00:00', label: '5AM-5:30AM'},
  {value: '05:30:00', label: '5:30AM-6AM'},
  {value: '06:00:00', label: '6AM-6:30AM'},
  {value: '06:30:00', label: '6:30AM-7AM'},
  {value: '07:00:00', label: '7AM-7:30AM'},
  {value: '07:30:00', label: '7:30AM-8AM'},
  {value: '08:00:00', label: '8AM-8:30AM'},
  {value: '08:30:00', label: '8:30AM-9AM'},
  {value: '09:00:00', label: '9AM-9:30AM'},
  {value: '09:30:00', label: '9:30AM-10AM'},
  {value: '10:00:00', label: '10AM-10:30AM'},
  {value: '10:30:00', label: '10:30AM-11AM'},
  {value: '11:00:00', label: '11AM-11:30PM'},
  {value: '11:30:00', label: '11:30AM-12PM'},
  {value: '12:00:00', label: '12PM-12:30PM'},
  {value: '12:30:00', label: '12:30PM-1PM'},
  {value: '13:00:00', label: '1PM-1:30PM'},
  {value: '13:30:00', label: '1:30PM-2PM'},
  {value: '14:00:00', label: '2PM-2:30PM'},
  {value: '14:30:00', label: '2:30PM-3PM'},
  {value: '15:00:00', label: '3PM-3:30PM'},
  {value: '15:30:00', label: '3:30PM-4PM'},
  {value: '17:00:00', label: '5PM-5:30PM'},
  {value: '17:30:00', label: '5:30PM-6PM'},
  {value: '18:00:00', label: '6PM-6:30PM'},
  {value: '18:30:00', label: '6:30PM-7PM'},
  {value: '19:00:00', label: '7PM-7:30PM'},
  {value: '19:30:00', label: '7:30PM-8PM'},
  {value: '20:00:00', label: '8PM-8:30PM'},
  {value: '20:30:00', label: '8:30PM-9PM'},
  {value: '21:00:00', label: '9PM-9:30PM'},
  {value: '21:30:00', label: '9:30PM-10PM'},
  {value: '22:00:00', label: '10PM-10:30PM'},
  {value: '22:30:00', label: '10:30PM-11PM'},
  {value: '23:00:00', label: '11PM-11:30AM'},
  {value: '23:30:00', label: '11:30PM-12AM'},
];

export const TIME_INTERVALS_IN_A_DAY_DOUBLE: {label: string; value: string}[] = [
  {value: '00:00:00', label: '12AM-2AM'},
  {value: '02:00:00', label: '2AM-4AM'},
  {value: '04:00:00', label: '4AM-6AM'},
  {value: '06:00:00', label: '6AM-8AM'},
  {value: '08:00:00', label: '8AM-10AM'},
  {value: '10:00:00', label: '10AM-12AM'},
  {value: '12:00:00', label: '12PM-2PM'},
  {value: '14:00:00', label: '2PM-4PM'},
  {value: '16:00:00', label: '4PM-6PM'},
  {value: '18:00:00', label: '6PM-8PM'},
  {value: '20:00:00', label: '8PM-10PM'},
  {value: '22:00:00', label: '10PM-12AM'},
];

interface TimeSlot {
  value: string;
  label: string;
}

/**
 *
 * @param incrementInMinutes
 * @returns an array of time slots in a day
 */
export function getTimeSlotsInADay(incrementInMinutes: number = 60): TimeSlot[] {
  const timeSlots: TimeSlot[] = [];
  for (let i = 0; i < 24; i++) {
    const hourValue = `${i.toString().padStart(2, '0')}:00:00`;
    timeSlots.push({label: '', value: hourValue});
    if (incrementInMinutes < 60 && 60 % incrementInMinutes === 0) {
      // Calculate partials
      for (let j = 1; j < 60 / incrementInMinutes; j++) {
        const partialHourValue = `${i.toString().padStart(2, '0')}:${(incrementInMinutes * j)
          .toString()
          .padStart(2, '0')}:00`;
        timeSlots.push({
          label: '',
          value: partialHourValue,
        });
      }
    } else if (incrementInMinutes !== 60) {
      throw new Error('Please enter a valid incrementInMinutes. A valid increment is a factor of 60.');
    }
  }
  return timeSlots;
}

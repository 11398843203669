import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable, of, Subject} from 'rxjs';
import {Eligibility} from '../../admisssion.types';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EligibilityService {
  constructor(private http: HttpClient) {}

  eligibility: Eligibility = {
    eligible: true,
  };
  eligibility$ = new BehaviorSubject<Eligibility>(this.eligibility);

  fetchEligibility(): Observable<Eligibility> {
    return this.http.get(`${environment.urls.host}v2/lead/eligibility`, {}).pipe(
      map((res: any) => {
        this.eligibility = res.data;
        this.eligibility$.next(this.eligibility);
        return this.eligibility;
      }),
      catchError((error: HttpErrorResponse) => {
        this.eligibility = {eligible: true};
        this.eligibility$.next(this.eligibility);
        return of(this.eligibility);
      }),
    );
  }
}

export enum StageNames {
  FRESH_LEAD = 'fresh_lead',
  APPLICATION_FORM = 'application_form',
  TEST = 'test',
  INTERVIEW = 'interview',
  DOCUMENTATION_PG = 'documentation_pg',
  DOCUMENTATION_UG = 'documentation_ug',
  SELECTION = 'selection',
  OFFER_LETTER = 'offer_letter',
  SEAT_BOOKING = 'seat_booking',
  REFUND = 'refund',
}
export enum SPECIAL_ROUTE_MAP {
  PROFILE = 'profile',
  HELP = 'help',
}

export enum TaskNames {
  UPDATE_PERSONAL_DETAILS = 'lj_update_personal_details',
  UPDATE_EDUCATION_DETAILS = 'lj_update_education_details',
  SCHEDULE_TEST = 'lj_schedule_test',
  RESCHEDULE_TEST = 'lj_reschedule_test',
  UPDATE_TEST_STATUS = 'lj_update_test_status',
  SCHEDULE_INTERVIEW = 'lj_schedule_interview',
  RESCHEDULE_INTERVIEW = 'lj_reschedule_interview',
  COMPLETE_INTERVIEW_QUESTION = 'lj_complete_interview_question',
  UPDATE_INTERVIEW_SCORE = 'lj_update_interview_status',
  UPLOAD_DOCUMENTS_PG = 'lj_upload_documents_pg',
  UPLOAD_DOCUMENTS_UG = 'lj_upload_documents_ug',
  VERIFY_DOCUMENTS_PG = 'lj_verify_documents_pg',
  VERIFY_DOCUMENTS_UG = 'lj_verify_documents_ug',
  MARK_INELIGIBLE = 'lj_mark_ineligible',
  MARK_ELIGIBLE = 'lj_mark_eligible',
  CHANGE_PROGRAM = 'lj_change_program',
  REQUEST_OFFER_LETTER = 'lj_request_offer',
  RELEASE_OFFER_LETTER = 'lj_release_offer',
  UPDATE_OFFER_LETTER = 'lj_update_offer',
  UPDATE_SELECTION_STATUS = 'lj_update_selection_status',
  ADD_PAYMENT = 'lj_add_payment',
  ACTIVATE_LEAD = 'lj_activate_lead',
  // VERIFY_PAYMENT = "lj_verify_payment",
  REQUEST_REFUND = 'lj_request_refund',
  APPROVE_REFUND = 'lj_approve_refund',
  REFUND_COMPLETE = 'lj_refund_complete',
  CANCEL_REFUND = 'lj_cancel_refund',
  // UPLOAD_PAP_AGREEMENT = "lj_upload_pap_agreement",
}

export enum GroupNames {
  APPLICATION = 'Application',
  SELECTION = 'Selection',
  TEST = 'Test',
  SCREENING = 'Screening',
  OFFER_LETTER = 'Offer Letter',
  SEAT_BOOKING = 'Seat Booking',
}

import {ClassLoginGuard} from './shared-core/guards/class-login.guard';
import {ClassLoginModule} from './class-login/class-login.module';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './shared-core/guards/auth.guard';
import {LoginGuard} from './shared-core/guards/login.guard';
import {AdmissionGuard} from './shared-core/guards/admission.guard';
import {WebviewPaymentHelperComponent} from './shared-payment/components/webview-payment-helper/webview-payment-helper.component';
import {WEBVIEW_PAYMENT_HELPER_COMPONENT_ROUTE} from './shared-payment/components/webview-payment-helper/webview-payment-helper.constants';

const routes: Routes = [
  {
    path: WEBVIEW_PAYMENT_HELPER_COMPONENT_ROUTE,
    component: WebviewPaymentHelperComponent,
  },
  {
    path: '',
    loadChildren: () => import('./loginnew/loginnew.module').then((m) => m.LoginnewModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'zoom/meeting/:meetingId',
    loadChildren: () => import('./zoom-link-open/zoom-link-open.module').then((m) => m.ZoomLinkOpenModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    // loadChildren: () => import('./profilenew/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'classes',
    loadChildren: () => import('./classes/classes.module').then((m) => m.ClassesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'classlist',
    loadChildren: () => import('./classlist/classlist.module').then((m) => m.ClasslistModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    // loadChildren: () => import('./dashboard-new/dashboard-new.module').then(m => m.DashboardNewModule),
    loadChildren: () => import('./final-dashboard/final-dashboard.module').then((m) => m.FinalDashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'time-table',
    loadChildren: () => import('./daytimetable/daytimetable.module').then((m) => m.DaytimetableModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admission',
    loadChildren: () => import('./admission-new/admission-new.module').then((m) => m.AdmissionNewModule),
    canActivate: [AdmissionGuard],
  },
  {
    path: 'admissions',
    redirectTo: 'admission',
  },
  {
    path: 'my-applications',
    loadChildren: () => import('./my-applications/my-applications.module').then((m) => m.MyApplicationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then((m) => m.JobsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'saved-jobs',
    loadChildren: () => import('./saved-jobs/saved-jobs.module').then((m) => m.SavedJobsModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'please-wait',
    loadChildren: () => import('./loaderscreen/loaderscreen.module').then((m) => m.LoaderscreenModule),
  },
  {
    path: 'freshdesk',
    loadChildren: () => import('./freshdesk/freshdesk.module').then((m) => m.FreshdeskModule),
  },
  {
    path: 'freshdesk-bonafide',
    loadChildren: () => import('./freshdesk/freshdesk.module').then((m) => m.FreshdeskModule),
  },
  {
    path: 'freshdesk-finance',
    loadChildren: () => import('./freshdesk/freshdesk.module').then((m) => m.FreshdeskModule),
  },
  {
    path: 'freshdesk/loan-assistance',
    loadChildren: () => import('./freshdesk/freshdesk.module').then((m) => m.FreshdeskModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./tandc/tandc.module').then((m) => m.TandcModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'admission/paymentsuccess',
    loadChildren: () => import('./payment-success/payment-success.module').then((m) => m.PaymentSuccessModule),
  },
  {
    path: 'admission/paymentfailed',
    loadChildren: () => import('./payment-failed/payment-failed.module').then((m) => m.PaymentFailedModule),
  },
  {
    path: 'fee-payment',
    loadChildren: () => import('./fee-payment/fee-payment.module').then((m) => m.FeePaymentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'refund-policy',
    loadChildren: () => import('./refund-policy/refund-policy.module').then((m) => m.RefundPolicyModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'class-login',
    loadChildren: () => import('./class-login/class-login.module').then((m) => m.ClassLoginModule),
    canActivate: [ClassLoginGuard],
  },
  {
    path: 'magic-link/:magicLink',
    loadChildren: () => import('./magic-link/magic-link.module').then((m) => m.MagicLinkModule),
  },
  {
    path: 'refund-desk',
    loadChildren: () => import('./freshdesk/freshdesk.module').then((m) => m.FreshdeskModule),
  },
  {
    path: 'content-feedback',
    loadChildren: () => import('./freshdesk/freshdesk.module').then((m) => m.FreshdeskModule),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

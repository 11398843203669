import {isPlatformBrowser} from '@angular/common';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {CookieService} from 'ngx-cookie';
import {Observable} from 'rxjs';
import {getUserDetailsFromWebviewContextOrCookie} from 'src/app/services/auth.helpers';

/**
 * Attaches auth tokens to the request
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) public req: any,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const ppuCookie = this.cookieService.get('ppu');
    // let cookie;
    // if (isPlatformBrowser(this.platformId)) {
    //   //if student or if lead
    //   cookie =
    //     (this.cookieService && this.cookieService.get('psu')) || (this.cookieService && this.cookieService.get('alu'));
    // } else {
    //   cookie = (this.req.cookies && this.req.cookies.psu) || (this.req.cookies && this.req.cookies.alu);
    // }

    const cookie = getUserDetailsFromWebviewContextOrCookie(this.cookieService)

    // If authorized
    if (Boolean(cookie)) {
      const authToken = Boolean(cookie) ? 'Bearer ' + cookie['session_key'] : undefined;

      const authorizedRequest = request.clone({
        headers: request.headers.set('Authorization', authToken),
      });
      return next.handle(authorizedRequest);
    } else {
      return next.handle(request);
    }
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {
  EDUCATION_LEVELS,
  IApiResponse,
  PgEducationLevels,
  PG_PROGRAMS_2023,
  SunstoneOfflinePgPrograms,
  SunstoneOfflineUgPrograms,
  SunstoneOnlinePgPrograms,
  SunstoneProgramIds,
  UgEducationLevels,
  UG_PROGRAMS_2023,
} from '../../../shared/shared.constants';

const programStringProxy = (target) => {
  return new Proxy(target, {
    get(obj, prop, value) {
      if (prop === 'includes') {
        return (arg) => {
          return obj[prop](arg?.toString());
        };
      }
      return obj[prop];
    },
  });
};

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  readonly HOST_URL = environment.urls.host;
  // readonly HOST_URL = `http://192.168.2.141:3004/`;
  // These values are fallback, until we have updated values from API
  offlineUgPrograms = programStringProxy(SunstoneOfflineUgPrograms);
  offlinePgPrograms = programStringProxy(SunstoneOfflinePgPrograms);
  offlineUgProgramsWithNames = UG_PROGRAMS_2023;
  offlinePgProgramsWithNames = PG_PROGRAMS_2023;
  onlinePgPrograms = programStringProxy(SunstoneOnlinePgPrograms);

  allPgPrograms = programStringProxy(SunstoneOfflinePgPrograms.concat(SunstoneOnlinePgPrograms));
  allOfflinePrograms = programStringProxy(SunstoneOfflineUgPrograms.concat(SunstoneOfflinePgPrograms));
  allBbaPrograms = programStringProxy([SunstoneProgramIds.BBA, SunstoneProgramIds.GLOBAL_IMMERSION_BBA]);

  constructor(private http: HttpClient) {
    this.fetchSegregatedPrograms();
  }

  checkQualificationMismatch(program: number, highestQualification: EDUCATION_LEVELS): boolean {
    if (!highestQualification) {
      return false;
    }
    if (UgEducationLevels.includes(highestQualification)) {
      return !this.offlineUgPrograms.includes(program);
    } else if (PgEducationLevels.includes(highestQualification)) {
      return !this.allPgPrograms.includes(program);
    }
    return true;
  }

  private async fetchSegregatedPrograms(): Promise<void> {
    this.http.get(`${this.HOST_URL}api/segregatedPrograms`).subscribe(
      (res: IApiResponse) => {
        if (res && res.success) {
          const segregatedPrograms = res.data;
          this.offlineUgPrograms = programStringProxy(segregatedPrograms.offlineUgPrograms);
          this.offlinePgPrograms = programStringProxy(segregatedPrograms.offlinePgPrograms);
          this.onlinePgPrograms = programStringProxy(segregatedPrograms.onlinePgPrograms);
          this.allPgPrograms = programStringProxy(segregatedPrograms.allPgPrograms);
          this.allOfflinePrograms = programStringProxy(segregatedPrograms.allOfflinePrograms);
        }
      },
      (err) => {},
    );
  }

  fetchSegregatedProgramsWithNames() {
    return this.http
      .get<{data: {offlineUgPrograms: {id: number; name: string}[]; offlinePgPrograms: {id: number; name: string}[]}}>(
        `${this.HOST_URL}api/segregatedPrograms?names=true`,
      )
      .pipe(pluck('data'));
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AppService} from 'src/app/services/app.service';
import {WidgetData} from './status-bar.types';

@Injectable({
  providedIn: 'root',
})
export class StatusBarService {
  closeSidebar = new BehaviorSubject<boolean>(false);
  widgetData = new BehaviorSubject<WidgetData>({} as WidgetData);
  displayPaymentStatus: boolean = true;

  constructor(private appService: AppService) {
    this.fetchAdmissionWidgetData();
  }

  async fetchAdmissionWidgetData() {
    try {
      const res = await this.appService.getApi('box/hub/admission-widget-v2?need_detailed_widget=1');
      if (res.success) {
        this.widgetData.next(res.data);
        // this.widgetData.getValue()?.stages.forEach((stage) => {
        //   if (stage.icon_identifier === 'radio') {
        //     if (stage?.message_identifier === 'waiting') {
        //       this.displayPaymentStatus = false;
        //     } else {
        //       this.displayPaymentStatus = true;
        //     }
        //   }
        // });
      }
    } catch (e) {
      console.log(e);
    }
  }
}

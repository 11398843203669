import {Action, createReducer, on} from '@ngrx/store';
import * as AdmissionActions from './admission.actions';
import {
  CampusSelectSteps,
  EligibilityProcessSteps,
  ICampusData,
  ICertification,
  IPaymentPlan,
  IProgramItem,
  IUserData,
  IWorkExperience,
  MasterProfileStep,
  TestSteps,
} from './admission.constants';
import {ICity, IState} from '../../shared/shared.constants';

export const admissionFeatureKey = 'admission';

export interface IEligiblePrograms {
  loading: boolean;
  programs: IProgramItem[];
  currentProgram: {
    program_id: string;
    eligibility: boolean;
    program: IProgramItem;
  };
  // currentProgramEligible: boolean;
  loadingError: boolean;
}

export interface ICampusSelect {
  programId: number;
  city: ICity;
  campuses: ICampusData[];
  campusesLoading: boolean;
  noCampusesInSelectedCity: boolean;
  campusId: number;
  paymentLoading: boolean;
  paymentPlans: IPaymentPlan[];
  paymentPlanId: number;
}

export interface IProfileData {
  certifications: ICertification[];
  skills: string[];
  workExperiences: IWorkExperience[];
}

export interface AdmissionState {
  studentData: IUserData;
  eligibilityStep: string;
  campusSelectStep: string;
  eligiblePrograms: IEligiblePrograms;
  campusSelectData: ICampusSelect;
  testStep: TestSteps;
  cities: ICity[];
  states: IState[];
  skills: string[];

  profileDrawerOpen: boolean;
  profileData: IProfileData;
  profileDataLoading: boolean;
  certificationEditId: number | null;
  workExpEditId: number | null;
  docFormEditId: number | null;
  masterProfileStep: MasterProfileStep;
  profileCompletionPercentage: number | null;
}

export const initialState: AdmissionState = {
  studentData: {
    id: 0,
    profile_completion_status: null,
  },
  eligibilityStep: EligibilityProcessSteps.EDUCATION_SELECT,
  campusSelectStep: CampusSelectSteps.PROGRAM_SELECT,
  eligiblePrograms: {
    loading: false,
    programs: [],
    currentProgram: {
      program_id: null,
      eligibility: true,
      program: null,
    },
    // currentProgramEligible: true,
    loadingError: false,
  },
  campusSelectData: {
    programId: null,
    city: null,
    campuses: [],
    campusesLoading: false,
    noCampusesInSelectedCity: false,
    campusId: null,
    paymentLoading: false,
    paymentPlans: [],
    paymentPlanId: null,
  },
  testStep: TestSteps.TEST_INFO,
  cities: [],
  states: [],
  skills: [],
  profileDrawerOpen: false,
  profileData: {
    certifications: [],
    skills: [],
    workExperiences: [],
  },
  profileDataLoading: false,
  certificationEditId: null,
  workExpEditId: null,
  docFormEditId: null,
  masterProfileStep: MasterProfileStep.MAIN,
  profileCompletionPercentage: null,
};

export const AdmissionReducer = createReducer(
  initialState,
  on(AdmissionActions.loadStudentDataSuccess, (state: AdmissionState, {studentData}) => ({
    ...state,
    studentData: {
      ...state.studentData,
      ...studentData,
    },
  })),
  on(AdmissionActions.updateStudentDataSuccess, (state: AdmissionState, {updateFields, leadDataReqs}) => ({
    ...state,
    studentData: {
      ...state.studentData,
      ...updateFields,
      lead_data_requests: mergeArray('key', state.studentData.lead_data_requests, leadDataReqs),
    },
  })),
  on(AdmissionActions.updateEligibilityStep, (state: AdmissionState, {step}) => ({...state, eligibilityStep: step})),
  on(AdmissionActions.updateCampusSelectStep, (state: AdmissionState, {step}) => ({...state, campusSelectStep: step})),
  on(AdmissionActions.updateTestStep, (state: AdmissionState, {step}) => ({...state, testStep: step})),
  on(AdmissionActions.updateCampusSelectData, (state: AdmissionState, {data}) => ({
    ...state,
    campusSelectData: {...state.campusSelectData, ...data},
  })),
  on(AdmissionActions.loadEligiblePrograms, (state: AdmissionState) => ({
    ...state,
    eligiblePrograms: {...state.eligiblePrograms, loading: true},
  })),
  on(AdmissionActions.loadEligibleProgramsSuccess, (state: AdmissionState, {currentProgram, programs}) => ({
    ...state,
    eligiblePrograms: {
      loading: false,
      programs,
      currentProgram,
      // currentProgramEligible: currentProgram?.eligibility ?? true,
      loadingError: false,
    },
  })),
  on(AdmissionActions.loadEligibleProgramsError, (state) => ({
    ...state,
    eligiblePrograms: {...state.eligiblePrograms, loadingError: true, loading: false},
  })),
  on(AdmissionActions.loadEligibleCampuses, (state: AdmissionState) => ({
    ...state,
    campusSelectData: {
      ...state.campusSelectData,
      campusesLoading: true,
      campuses: [],
      campusId: null,
      noCampusesInSelectedCity: false,
    },
  })),
  on(AdmissionActions.loadEligibleCampusesSuccess, (state: AdmissionState, {campuses, noCampusesInSelectedCity}) => ({
    ...state,
    campusSelectData: {...state.campusSelectData, campuses, campusesLoading: false, noCampusesInSelectedCity},
  })),
  on(AdmissionActions.loadPaymentPlans, (state: AdmissionState) => ({
    ...state,
    campusSelectData: {
      ...state.campusSelectData,
      paymentLoading: true,
      paymentPlans: [],
      paymentPlanId: null,
    },
  })),
  on(AdmissionActions.loadPaymentPlansSuccess, (state: AdmissionState, {paymentPlans}) => ({
    ...state,
    campusSelectData: {...state.campusSelectData, paymentLoading: false, paymentPlans},
  })),
  on(AdmissionActions.toggleProfileDrawer, (state: AdmissionState, {profileDrawerState}) => ({
    ...state,
    profileDrawerOpen: profileDrawerState !== undefined ? profileDrawerState : !state.profileDrawerOpen,
  })),
  on(AdmissionActions.loadStatesSuccess, (state: AdmissionState, {states}) => ({
    ...state,
    states,
  })),
  on(AdmissionActions.loadCitiesSuccess, (state: AdmissionState, {cities}) => ({
    ...state,
    cities,
  })),
  on(AdmissionActions.setMasterProfileStep, (state: AdmissionState, {step}) => ({
    ...state,
    masterProfileStep: step,
    // If returning to main clear the following fields:
    certificationEditId: step === MasterProfileStep.MAIN ? null : state.certificationEditId,
    workExpEditId: step === MasterProfileStep.MAIN ? null : state.workExpEditId,
    docFormEditId: step === MasterProfileStep.MAIN ? null : state.docFormEditId,
  })),
  on(AdmissionActions.deleteLeadDataReqSuccess, (state: AdmissionState, {docKey}) => ({
    ...state,
    studentData: {
      ...state.studentData,
      lead_data_requests: state.studentData.lead_data_requests.filter((item) => item.key !== docKey),
    },
  })),
  on(AdmissionActions.setDocFormEditId, (state: AdmissionState, {docFormEditId}) => ({
    ...state,
    docFormEditId,
  })),
  on(
    AdmissionActions.loadProfileCompletionPercentageSuccess,
    (state: AdmissionState, {profileCompletionPercentage}) => ({
      ...state,
      profileCompletionPercentage,
    }),
  ),
  on(AdmissionActions.clearAdmissionStore, () => ({
    ...initialState,
  })),
);

const mergeArray = (key, arr1, arr2) => {
  if (!arr2) {
    return arr1;
  }
  const newArr = arr1.filter((arrItem) => {
    const itemKey = arrItem[key];
    const keyExists = arr2.some((item) => item[key] === itemKey);
    return !keyExists;
  });
  return [...newArr, ...arr2];
};

export function admissionReducer(state: AdmissionState | undefined, action: Action): any {
  return AdmissionReducer(state, action);
}

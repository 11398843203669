import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {environment} from 'src/environments/environment';
import {
  getWebviewContext,
  getUserDetailsFromWebviewContextOrCookie
} from '../../../services/auth.helpers';

@Injectable({
  providedIn: 'root'
})
export class FlutterRedirectionService {

  experimentUserDigits = 
    environment.envName == 'development' ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] : 
    environment.envName == 'staging' ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] :
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  excludedUtms = environment.envName == 'development' ? [] : ['google_lg_web', 'Google', 'google_lg_If', 'google-play']
  includedUtmSourceMedium: any = {
    "google_lg_web": ["Search_Flutter"]
  }

  constructor(
    public cookie: CookieService
  ) { }

  checkIfFlutterUser(){
    if(!window.location.href.includes('localhost')){
      return true;
    }
    return false;
  }

  checkIfFlutterUserOld() {
    // console.log('?????? INSIDE checkIfFlutterUser');
    // let userCookieValue = this.cookie.get('alu') || this.cookie.get('psu');
    // console.log('?????? INSIDE checkIfFlutterUser - COOKIE VALUE', userCookieValue);
    // if(!userCookieValue) {
    //   return false
    // }
    // console.log('?????? INSIDE checkIfFlutterUser - PROCEEDING');
    // const userDetail = JSON.parse(userCookieValue);

    const userDetail = getUserDetailsFromWebviewContextOrCookie(this.cookie)
    console.log('?????? INSIDE checkIfFlutterUser - COOKIE VALUE', userDetail);
    const userMobile = userDetail?.mobile?.toString();
    const userId = userDetail?.id?.toString();
    const lastDigitToCheck = environment.envName == 'prod'
      ? userId?.toString()?.slice(-1)
      : userMobile?.toString()?.slice(-1);
    const userUtmSource = userDetail?.utm_source;
    const userUtmMedium = userDetail?.utm_medium;
    console.log(`?????? INSIDE checkIfFlutterUser - VALUES - userMobile: ${userMobile}, userId: ${userId}, lastDigit: ${lastDigitToCheck}, userUtmSource: ${userUtmSource}}`);

    if(
      !window.location.href.includes('localhost') &&
      (
        (
          lastDigitToCheck &&
          this.experimentUserDigits.includes(+lastDigitToCheck) &&
          !this.excludedUtms.includes(userUtmSource as any)
        ) ||
        this.includedUtmSourceMedium?.[userUtmSource]?.includes(userUtmMedium)
      )
    ) {
      console.log(`?????? INSIDE checkIfFlutterUser - FLUTTER USER`)
      return true;
    }

    console.log(`?????? INSIDE checkIfFlutterUser - NOT FLUTTER USER`);
    return false
  }

  checkIfWebview() {
    return true
  }

  checkIfWebviewNew() {
    let isOpenedFlutterWeb = false;
    if (parent !== window) {
      isOpenedFlutterWeb = document.referrer.includes('sunstone.in');
    }
    console.log(`?????? INSIDE checkIfWebviewNew: isOpenedFlutterWeb - ${isOpenedFlutterWeb}, getWebviewContext - ${getWebviewContext}`);
    if (isOpenedFlutterWeb || getWebviewContext()) {
      return true;
    }
    return false;
  }

  checkIfWebviewOld() {
    console.log(`?????? INSIDE checkIfWebview`);
    console.log(`?????? INSIDE checkIfWebview - VALUES - window.location.href: ${window.location.href}, sessionStorage.getItem('webview'): ${sessionStorage.getItem('webview')}, getWebviewContext(): ${getWebviewContext()}`);
    if (
      window.location.href.includes('webview=true') ||
      sessionStorage.getItem('webview') ||
      getWebviewContext()
    ) {
      console.log(`?????? INSIDE checkIfWebview - WEBVIEW USER`);
      sessionStorage.setItem('webview', 'true');
      return true
    }
    console.log(`?????? INSIDE checkIfWebview - NOT WEBVIEW USER`);
    return false
  }
}

import {Injectable} from '@angular/core';
import {BackendResponse} from '../../../http';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {lastValueFrom, Observable, of} from 'rxjs';
import {TaskNames} from './stage-resolver.enums';
import {getCurrentStage} from './stage-resolver.utils';
import {MODULE_ROUTE_MAP} from './stage-resolver.constants';
import {Router} from '@angular/router';
import {StageResolverService} from './stage-resolver.service';
import {EligibilityService} from '../../shared/services/eligibility.service';

@Injectable({
  providedIn: 'root',
})
export class TaskExecutorService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private stageResolver: StageResolverService,
    private eligibilityService: EligibilityService,
  ) {}

  executeTask(taskName: TaskNames, taskData: any = {}, autoRouting = true): Observable<BackendResponse<any>> {
    return this.httpClient
      .post<BackendResponse<any>>(`${environment.urls.host}v2/lead/task`, {
        taskName,
        taskData,
      })
      .pipe(
        tap((res) => {
          this.stageResolver.updateLeadJourney(res.data);
          if (autoRouting) {
            this.handleNavigation(res.data);
          }
        }),
        catchError((err) => of(err)),
      );
  }

  executeFormTask(taskForm: FormData, autoRouting = true): Observable<BackendResponse<any>> {
    return this.httpClient.post<BackendResponse<any>>(`${environment.urls.host}v2/lead/task`, taskForm).pipe(
      tap((res) => {
        this.stageResolver.updateLeadJourney(res.data);
        if (autoRouting) {
          this.handleNavigation(res.data);
        }
      }),
      catchError((err) => of(err)),
    );
  }

  async handleNavigation(journeyData: any): Promise<void> {
    const currentStage = getCurrentStage(journeyData);
    const eligibility = await lastValueFrom(this.eligibilityService.fetchEligibility());
    if (!eligibility.eligible) {
      this.router.navigate(['admission', 'not-eligible']);
      return;
    }
    this.router.navigate(['admission', MODULE_ROUTE_MAP[currentStage]]);
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {pluck, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {BackendResponse} from '../http';

interface NamedProperty {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class StateCityService {
  private stateList: NamedProperty[] = [];
  constructor(private http: HttpClient) {}

  getStateListSnapshot() {
    return this.stateList;
  }
  getStateList(): Observable<NamedProperty[]> {
    if (this.stateList.length) {
      return of(this.stateList);
    } else {
      return this.http.get<BackendResponse<NamedProperty[]>>(`${environment.urls.host}api/fetchStateList`).pipe(
        pluck('data'),
        tap((stateList) => (this.stateList = stateList)),
      );
    }
  }

  /**
   *
   * @param stateId
   * @returns all city list filtered by states
   */
  getCityList(stateId: number) {
    return this.http
      .get<BackendResponse<NamedProperty[]>>(`${environment.urls.host}/api/fetchCityFromState?state_id=${stateId}`)
      .pipe(pluck('data'));
  }
}
